import { Row, Col, Tabs, Tab, Card, Button, Spinner, Modal } from "react-bootstrap";
import { toast, ToastContainer } from "react-toastify";
import { useTranslation } from "react-i18next";
import Menu from "../layouts/Menu";
import { useEffect, useState } from "react";
import TicketsService from "../../services/Tickets.service";
import UserModel from "../../models/user.model";
import TicketsModel from "../../models/tickets.model";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMoneyBillWave, faQrcode } from "@fortawesome/free-solid-svg-icons";
import QRCode from "react-qr-code";
import eventBus from "../../services/EventBus";

const Tickets = () => {
    const { t } = useTranslation();
    const ticketsService = new TicketsService();
    const ticketTypes: any = {active: '0', inactive: '1'};

    const [userData, setUserData] = useState<UserModel>({});
    const [activeTickets, setActiveTickets] = useState<TicketsModel[]>([]);
    const [inactiveTickets, setInactiveTickets] = useState<TicketsModel[]>([]);
    const [inAction, setInAction] = useState(false);
    const [showQRModal, setShowQRModal] = useState(false);
    const [showInvoiceModal, setShowInvoiceModal] = useState(false);
    const [qrModalTicket, setQRModalTicket] = useState<TicketsModel>();
    const [filter, setFilter] = useState('');

    document.body.className='';

    useEffect(() => {
        const userData = localStorage.getItem('userData');
        const user: UserModel = userData ? JSON.parse(userData) : {};
        if (user) {
            setUserData(user);
            loadTickets(ticketTypes.active, user);
            loadTickets(ticketTypes.inactive, user);
        }
        eventBus.on('ticketsUpdate', (payload: any) => {
            loadTickets(ticketTypes.active, user);
            loadTickets(ticketTypes.inactive, user);
        });
    }, []);

    useEffect(() => {
        const userData = localStorage.getItem('userData');
        const user: UserModel = userData ? JSON.parse(userData) : {};
        if (user) {
            setUserData(user);
            loadTickets(ticketTypes.active, user, filter);
            loadTickets(ticketTypes.inactive, user, filter);
        }
    },[filter]);

    const loadTickets = (ticketType?: number, user?: UserModel, filterKey?: string) => {
        setInAction(true);
        setActiveTickets([]);
        setInactiveTickets([]);
        if (user?.id && ticketType) {
            ticketsService.getUserTickets(user, ticketType).then((response: any) => {
                if ('status' in response) {
                    if (response.status === 200) {
                        if (ticketType === ticketTypes.active) {
                            if (filterKey && filterKey !== '' && response && response.data && response.data.data) {
                                const filteredList = response.data.data.filter((item: any ) => {
                                    return item.product.entity === filterKey;
                                });
                                setActiveTickets(filteredList);
                            }
                            else {
                                setActiveTickets(response.data.data);
                            }
                        }
                        else {
                            if (filterKey && filterKey !== '' && response && response.data && response.data.data) {
                                const filteredList = response.data.data.filter((item: any ) => {
                                    return item.product.entity === filterKey;
                                });
                                setInactiveTickets(filteredList);
                            }
                            else {
                                setInactiveTickets(response.data.data);
                            }
                        }
                    }
                    else {
                        toast.error(t('profileUpdateError'));
                    }
                }
                setInAction(false);
            });
        }
        else {
            toast.error(t('userNotLoggedIn'));
        }
    };

    const displayQRCode = (ticket: TicketsModel) => {
        console.log(ticket);
        setQRModalTicket(ticket);
        setShowQRModal(true);
    };

    const sendBill = () => {
        setInAction(true);
        setShowInvoiceModal(false);
        if (qrModalTicket) {
            ticketsService.resendInvoice(userData, qrModalTicket.facture_ref).then((reponse: any) => {
                if ('status' in reponse) {
                    if (reponse.status === 200) {
                        toast.success(t('invoiceSent'));
                    }
                    else {
                        toast.error(t('invoiceNotSent'));
                    }
                }
                setInAction(false);
            });
        }
        else {
            toast.error(t('unknownError'));
        }
    };

    const handleInvoiceResend = (ticket: TicketsModel) => {
        setQRModalTicket(ticket);
        setShowInvoiceModal(true);
    };

    const handleInvoiceClose = () => {
        setShowInvoiceModal(false);
    };

    return (
        <>
            <Menu />
            <ToastContainer />
            <Row className="content align-items-start">
                <Col lg={12} md={12} sm={12} xs={12}>
                    <Tabs>
                        <Tab eventKey={'activeTicktes'} title={t('activeTickets')}>
                            <Tab.Content>
                                {activeTickets ?  
                                <Card className="tab-card">
                                    <Card.Body>
                                    {activeTickets.length === 0 && inAction ? 
                                    <Row>
                                        <Col lg={12} className="centered">
                                            <Spinner animation="border" variant="danger" />
                                            <p>{t('noTickets')}</p>
                                        </Col>
                                    </Row>
                                    :
                                    <>
                                        <strong className="tickets-number">{activeTickets.length}</strong> <small className="tickets-title">{t('darab')} {t('jegy található ebben a kategóriában')}</small>
                                        <button type="button" className={
                                            filter === '' ? 
                                            'btn color-danger bordered btn btn-primary btn-middle-nsu' : 
                                            'btn color-danger bordered btn btn-primary btn-none-nsu'} 
                                            onClick={() => {setFilter('')}}>{'Összes'}</button>
                                        <button type="button" className={
                                            filter === '2' ? 
                                            'btn color-danger bordered btn btn-primary btn-middle-nsu' : 
                                            'btn color-danger bordered btn btn-primary btn-none-nsu'} 
                                            onClick={() => {setFilter('2')}}>{'Duna Aréna'}</button>
                                        <button type="button" className={
                                            filter === '4' ? 
                                            'btn color-danger bordered btn btn-primary btn-middle-nsu' : 
                                            'btn color-danger bordered btn btn-primary btn-none-nsu'} 
                                            onClick={() => {setFilter('4')}}>{'Hajós Alfréd'}</button>
                                        <button type="button" className={
                                            filter === '5' ? 
                                            'btn color-danger bordered btn btn-primary btn-middle-nsu' : 
                                            'btn color-danger bordered btn btn-primary btn-none-nsu'} 
                                            onClick={() => {setFilter('5')}}>{'Császár - Komjádi'}</button>
                                        <br />
                                        <hr />
                                        {inAction ? 
                                        <Row>
                                            <Col lg={12} className="centered">
                                                <Spinner animation="border" variant="danger" />
                                                <p>{t('noTickets')}</p>
                                            </Col>
                                        </Row>
                                        : 
                                        <table className="table">
                                            <thead>
                                                <tr>
                                                    <th>{t('Helyszín')}</th>
                                                    <th>{t('Jegytípus')}</th>
                                                    <th>{t('Számla sorszáma')}</th>
                                                    <th>{t('Vásárlás dátuma')}</th>
                                                    <th>{t('Lejárati dátum')}</th>
                                                    <th>{t('QR kód')}</th>
                                                    <th>{t('Számla újraküldés')}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {activeTickets.map((ticket: TicketsModel) => (
                                                <tr>
                                                    <td className="ticket-location">{ticket.entity_label}</td>
                                                    <td className="ticket-name">{ticket.product?.label}</td>
                                                    <td className="ticket-location">{ticket.facture_ref}</td>
                                                    <td className="ticket-date">{ticket.date_creation}</td>
                                                    <td className="ticket-date">{ticket.expired}</td>
                                                    <td><Button onClick={() => {displayQRCode(ticket)}} className="btn color-danger" title={t('QR kód megjelenítése')}><FontAwesomeIcon icon={faQrcode}></FontAwesomeIcon></Button></td>
                                                    <td><Button onClick={() => {handleInvoiceResend(ticket)}} className="btn color-danger" title={t('Számla újraküldése')}><FontAwesomeIcon icon={faMoneyBillWave}></FontAwesomeIcon></Button></td>
                                                </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                        }
                                    </>
                                    }
                                    </Card.Body>
                                </Card>
                                : 
                                <Card className="tab-card">
                                <Card.Body>
                                <Row>
                                    <Col lg={12} className="centered">
                                        <p>{t('noActiveTickets')}</p>
                                    </Col>
                                </Row>
                                </Card.Body>
                                </Card>
                                }
                            </Tab.Content>
                        </Tab>
                        <Tab eventKey={'inactiveTicktes'} title={t('inactiveTickets')}>
                            <Tab.Content>
                                {inactiveTickets ? 
                                <Card className="tab-card">
                                        <Card.Body>
                                        {inactiveTickets.length === 0 && inAction ? 
                                        <Row>
                                            <Col lg={12} className="centered">
                                                <Spinner animation="border" variant="danger" />
                                                <p>{t('noTickets')}</p>
                                            </Col>
                                        </Row>
                                        :
                                        <>
                                            <strong className="tickets-number">{inactiveTickets.length}</strong> <small className="tickets-title">{t('darab')} {t('jegy található ebben a kategóriában')}</small>
                                            <button type="button" className={
                                                filter === '' ? 
                                                'btn color-danger bordered btn btn-primary btn-middle-nsu' : 
                                                'btn color-danger bordered btn btn-primary btn-none-nsu'} 
                                                onClick={() => {setFilter('')}}>{'Összes'}</button>
                                            <button type="button" className={
                                                filter === '2' ? 
                                                'btn color-danger bordered btn btn-primary btn-middle-nsu' : 
                                                'btn color-danger bordered btn btn-primary btn-none-nsu'} 
                                                onClick={() => {setFilter('2')}}>{'Duna Aréna'}</button>
                                            <button type="button" className={
                                                filter === '4' ? 
                                                'btn color-danger bordered btn btn-primary btn-middle-nsu' : 
                                                'btn color-danger bordered btn btn-primary btn-none-nsu'} 
                                                onClick={() => {setFilter('4')}}>{'Hajós Alfréd'}</button>
                                            <button type="button" className={
                                                filter === '5' ? 
                                                'btn color-danger bordered btn btn-primary btn-middle-nsu' : 
                                                'btn color-danger bordered btn btn-primary btn-none-nsu'} 
                                                onClick={() => {setFilter('5')}}>{'Császár - Komjádi'}</button>
                                            <br />
                                            <hr />
                                            <table className="table">
                                                <thead>
                                                    <tr>
                                                        <th>{t('Helyszín')}</th>
                                                        <th>{t('Jegytípus')}</th>
                                                        <th>{t('Számla sorszáma')}</th>
                                                        <th>{t('Vásárlás dátuma')}</th>
                                                        <th>{t('Felhasználás dátuma')}</th>
                                                        <th>{t('QR kód')}</th>
                                                        <th>{t('Számla újraküldés')}</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {inactiveTickets.map((ticket: TicketsModel) => (
                                                    <tr>
                                                        <td className="ticket-location">{ticket.entity_label}</td>
                                                        <td className="ticket-name">{ticket.product?.label}</td>
                                                        <td className="ticket-location">{ticket.facture_ref}</td>
                                                        <td className="ticket-date">{ticket.date_creation}</td>
                                                        <td className="ticket-date">{ticket.date_validation}</td>
                                                        <td><Button onClick={() => {displayQRCode(ticket)}} className="btn color-danger" title={t('QR kód megjelenítése')}><FontAwesomeIcon icon={faQrcode}></FontAwesomeIcon></Button></td>
                                                        <td><Button onClick={() => {handleInvoiceResend(ticket)}} className="btn color-danger" title={t('Számla újraküldése')}><FontAwesomeIcon icon={faMoneyBillWave}></FontAwesomeIcon></Button></td>
                                                    </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </>
                                        }
                                        </Card.Body>
                                    </Card>
                                : 
                                <Card className="tab-card">
                                <Card.Body>
                                <Row>
                                    <Col lg={12} className="centered">
                                        <p>{t('noInactiveTickets')}</p>
                                    </Col>
                                </Row>
                                </Card.Body>
                                </Card>
                                }
                            </Tab.Content>
                        </Tab>
                    </Tabs>
                </Col>
            </Row>

            <Modal show={showQRModal} size={'lg'} onHide={() => setShowQRModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>{t('A jegy QR kódja')}</Modal.Title>
                </Modal.Header>
                <Modal.Body className="ticket-frame">
                    <Row>
                        <Col lg={6} className="centered">
                            <QRCode value={qrModalTicket?.unique_number ? qrModalTicket?.unique_number : '0'} bgColor={'#FFFFFF'} fgColor={'#000000'} size={300} />
                        </Col>
                        <Col lg={6}>
                            <h2 className="label-location">{qrModalTicket?.entity_label}</h2>
                            <h1 className="label-type">{qrModalTicket?.product?.label}</h1>
                            <h3 className="label-invoice">{t('Sorszám')}: {qrModalTicket?.facture_ref}</h3>
                            <h3 className="label-expiration">{t('Érvényes')}: {qrModalTicket?.expired}</h3>
                            <h3 className="label-expiration">{t('Felhasználva')}: {qrModalTicket?.date_validation ? qrModalTicket?.date_validation : '-'}</h3>
                            <small className="label-info">{t('Kérjük ügyeljen jegye biztonságára! Az itt szereplő QR kódot ne adja át más személynek, mivel illetéktelen személy hozzáférése esetén a jegy felhasználásra kerülhet.')}</small>
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>

            <Modal show={showInvoiceModal} onHide={() => setShowInvoiceModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>{t('Számla újraküldés')}</Modal.Title>
                </Modal.Header>
                <Modal.Body className="ticket-frame">
                    {t('Valóba újra szeretné küldeni a számlát a vásárláskot megadott email címre?')}
                </Modal.Body>
                <Modal.Footer>
                    <Button className="btn color-danger" onClick={sendBill}>{t('Igen')}</Button>
                    <Button className="btn btn-none-nsu" onClick={handleInvoiceClose}>{t('Mégsem')}</Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default Tickets;