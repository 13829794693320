import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import 'react-toastify/dist/ReactToastify.css';

const Confirmation = () => {
    const navigate = useNavigate();

    useEffect(() => {
        localStorage.removeItem('userData');
        localStorage.removeItem('cart');
        navigate('/login');
    }, []);

    return (
        <>
        </>
    );
};

export default Confirmation;