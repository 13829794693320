import axios from "axios";
import config from "../config.json";
import CartModel from "../models/cart.model";
import UserModel from "../models/user.model";

class PaymentService {

    private endpointPrefix = config.API.URL + '/payment';
    private headerConfig = {
        'Content-Type': 'application/json'
    };

    public startPayment(cartdata: CartModel, userdata: UserModel) {
        return new Promise<any>((resolve, reject) => {
            let cartInfo: any = {total: '', cart: {}};
            cartInfo['total'] = cartdata.total ? Math.round(cartdata.total) : '0';
            cartInfo['billing_address_id'] = cartdata.total ? Math.round(cartdata.billing_address_id ? cartdata.billing_address_id : 0) : 0;
            cartInfo['SMB'] = userdata.SMB ? userdata.SMB : '';
            cartdata.cart?.forEach((cart) => {
                if (cart.id) {
                    cartInfo.cart[cart.id] = cart.quantity;
                }
            });
            axios.post(this.endpointPrefix, cartInfo, {headers: this.headerConfig})
            .then((response: any) => {
                resolve(response);
            })
            .catch((error) => {
                resolve(error.response);
            });
        });
    }

    public checkPaymentStatus(transactionId: string, userdata: UserModel) {
        return new Promise<any>((resolve, reject) => {
            axios.get(this.endpointPrefix + '/status/' + transactionId + '?SMB=' + userdata.SMB)
            .then((response: any) => {
                resolve(response);
            })
            .catch((error) => {
                resolve(error.response);
            });
        });
    }

}

export default PaymentService;