import React from 'react';
import { useState, useEffect } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import './App.scss';

import Layout from './components/Layout';
import Nopage from './components/Nopage';
import Dashboard from './components/dashboard/Dashboard';
import Login from './components/authentication/Login/Login';
import Logout from './components/authentication/Logout/Logout';
import Register from './components/authentication/Registration/Register';
import Reminder from './components/authentication/PasswordReminder/Reminder';
import PasswordChange from './components/authentication/PasswordChange/PasswordChange';
import Places from './components/places/Places';
import Tickets from './components/tickets/Tickets';
import Profile from './components/Profile/Profile';
import PaymentCompleted from './components/payment/PaymentCompleted';
import Reservations from './components/reservations/Reservations';

import UserModel from './models/user.model';
import Confirmation from './components/authentication/Registration/Confirmation';
import UserService from './services/User.service';
import CartButton from './components/common/CartButton';
import Order from './components/order/Order';
import PaymentCheck from './components/payment/PaymentCheck';

function App() {
  const [login, setLogin] = useState(false);
  const [minimalData, setMinimalData] = useState(false);
  const userService = new UserService();

  useEffect(() => {
    const user = localStorage.getItem('userData');
    const userData: UserModel = user ? JSON.parse(user) : {};
    if (userData.id) {
      setLogin(true);
    } else {
      setLogin(false);
    }

    setMinimalData(userService.checkForUserMinimalInformations(userData));
  }, []);

  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<Layout />} />
        <Route index element={ login ? minimalData ? <Dashboard /> : <Profile /> : <Login />} />
        <Route path="/dashboard" element={login ? minimalData ? <Dashboard /> : <Profile /> : <Login />} />
        <Route path="/login" element={<Login />} />
        <Route path="/logout" element={login ? <Logout /> : <Login />} />
        <Route path="/registration" element={<Register />} />
        <Route path="/confirmation" element={<Confirmation />} />
        <Route path="/reminder" element={<Reminder />} />
        <Route path="/passwordchange" element={<PasswordChange />} />
        <Route path="/passwordchange/:token" element={<PasswordChange />} />
        <Route path="/places" element={login ? minimalData ? <Places subpage={'places'} />: <Profile /> : <Login />} />
        <Route path="/order" element={login ? minimalData ? <CartButton type="details" /> : <Profile /> : <Login />} />
        <Route path="/tickets" element={login ? minimalData ? <Tickets /> : <Profile /> : <Login />} />
        <Route path="/reservations" element={login ? minimalData ? <Reservations /> : <Profile /> : <Login />} />
        <Route path="/settings" element={login ? minimalData ? <Profile /> : <Profile /> : <Login />} />
        <Route path="/startpayment" element={login ? minimalData ? <Order /> : <Profile /> : <Login />} />
        <Route path="/checkpayment/:transactionID" element={<PaymentCheck />} />
        <Route path="/profile" element={login ? <Profile /> : <Login />} />

        <Route path="/transaction-completed/:transactionID" element={<PaymentCompleted />} />

        <Route path="*" element={<Nopage />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
