import axios from "axios";
import config from "../config.json";

class ReservationsService {

    private endpointPrefix = config.API.URL + '/reservation';
    private headerConfig = {
        'Content-Type': 'application/json'
    };
    public getAreas(placeID: string) {
        return new Promise<any>((resolve, reject) => {
            axios.get(this.endpointPrefix + '/areas/' + placeID, {headers: this.headerConfig})
            .then((response: any) => {
                resolve(response);
            })
            .catch((error) => {
                resolve(error);
            });
        });
    }

    public getTimeTables(placeID: string, area: string, day: string) {
        return new Promise<any>((resolve, reject) => {
            axios.get(this.endpointPrefix + '/info/' + placeID + '/' + area + '/' + day, {headers: this.headerConfig})
            .then((response: any) => {
                resolve(response);
            })
            .catch((error) => {
                resolve(error);
            });
        });
    }

}

export default ReservationsService;