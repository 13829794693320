import { useEffect, useState } from "react";
import { Col, Row, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import UserModel from "../../models/user.model";
import UserService from "../../services/User.service";
import { toast, ToastContainer } from "react-toastify";


const BilliingAddressSelect = () => {
    const { t } = useTranslation();
    const [userData, setUserData] = useState<UserModel>();
    const [billingAddressId, setBillingAddressId] = useState('');
    const userService = new UserService();

    document.body.className='';

    useEffect(() => {
        const user = localStorage.getItem('userData');
        const userInfo = user ? JSON.parse(user) as UserModel : {};
        if (userInfo && userInfo.id) {
            userService.getUserData(userInfo.id).then((response: any) => {
                if ('status' in response) {
                    if (response.status === 200) {
                        const userInfo = response.data.data;
                        setUserData(userInfo);
                        const defaultBillingAddressID = userInfo && userInfo.billingaddresses && userInfo.billingaddresses?.length > 0 ? userInfo.billingaddresses[0].id : '';
                        const defaultBillingAddress = defaultBillingAddressID ? defaultBillingAddressID.toString() : '';
                        setBillingAddressId(defaultBillingAddress);
                        const cart = localStorage.getItem('cart');
                        const parsedCart = cart? JSON.parse(cart) : {};
                        parsedCart.billing_address_id = defaultBillingAddress;
                        localStorage.setItem('cart', JSON.stringify(parsedCart));                
                    }
                }
            });
        }
        else {
            toast.error(t('noUserError'));
        }
    }, []);

    const handleBillingAddressChange = (event: any) => {
        const { value } = event.target;
        const cart = localStorage.getItem('cart');
        const parsedCart = cart? JSON.parse(cart) : {};
        parsedCart.billing_address_id = value;
        localStorage.setItem('cart', JSON.stringify(parsedCart));
        setBillingAddressId(value);
    };

    return(
        <Row>
            <ToastContainer />
            <Col lg={12} md={12} sm={12} xs={12} className="align-top">
                <Form.Group className="mb-3" controlId="title">
                    <Form.Label>{t('Számlázási cím kiválasztása')}</Form.Label>
                    <Form.Select className="nsu-form-control" name="billingAddressId" value={billingAddressId} onChange={handleBillingAddressChange}>
                        {userData && userData.billingaddresses && userData?.billingaddresses.length > 0 ? userData.billingaddresses.map((option: any, index) => (
                            <option key={index} value={option.id}>{option.billingname} {option.billingzip} {option.billingcity} {option.billingaddress}</option>
                        )) : null}
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">
                        {t('validText')}
                    </Form.Control.Feedback>
                </Form.Group>
            </Col>
        </Row>
    );
};

export default BilliingAddressSelect;