import { useEffect, useState } from "react";
import { Col, Container, Row, Card, Form, Button, Figure, Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import PasswordModel from '../../../models/password.model';
import UserService from '../../../services/User.service';
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { useParams } from "react-router-dom";

const PasswordChange = () => {
    const { t } = useTranslation();
    const userService = new UserService();
    const [passwordData, setPasswordData] = useState<PasswordModel>({});
    const [validated, setValidated] = useState(false);
    const [emptyToken, setEmptyToken] = useState(true);
    const [inAction, setInAction] = useState(false);
    const navigate = useNavigate();
    const { token } = useParams();

    document.body.className='authBody';

    useEffect(() => {
        if (token) {
            const pass = {remindercode: token};
            setPasswordData(pass);
            setEmptyToken(false);
        }
    },[]);

    const onReminderSend = (event: any) => {
        setInAction(true);
        const form = event.currentTarget;
        if (form.checkValidity() === false && passwordData.password === passwordData.password_repeat) {
            setValidated(true);
        }
        else {
            userService.changePassword(passwordData).then((response: any) => {
                if ('status' in response) {
                    if (response.status === 200) {
                        toast.success(t('passwordchangeSuccessfull'));
                        localStorage.removeItem('userData');
                        const timer = setTimeout(() => {
                            navigate('/login');
                            clearTimeout(timer);
                        }, 2000);
                    }
                    else {
                        toast.error(t('passwordchangeError'));
                    }
                }
                setInAction(false);
            });
        }
        setInAction(false);
        event.preventDefault();
        event.stopPropagation();
    };

    const handleFormChange = (event: any) => {
        const { name, value } = event.target;
        setPasswordData({...passwordData, [name]: value });
    };

    const onBack = () => {
        localStorage.removeItem('userData');
        navigate('/login');
    };

    return (
        <Container>
            <ToastContainer />
            <Row className="content">
                <Col lg={4} md={6} sm={12} xs={12}>
                    <h1 className="centered-text-input">{t('focimPassChange')}</h1>
                    <Form noValidate validated={validated} onSubmit={onReminderSend}>
                        <Row>
                            <Col lg={12} md={12} sm={12} xs={12}>
                                <Card className="login">
                                    <Card.Body>
                                        <Row>
                                            <Col lg={12} md={12} sm={12} xs={12}>
                                                <Figure className="logo"></Figure>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col lg={12} md={12} sm={12} xs={12}>
                                                <h1>{t('passChangeData')}</h1>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col lg={12} md={12} sm={12} xs={12}>
                                                <Form.Group className="mb-3" as={Col} controlId="remindercode">
                                                    <Form.Label>{t('Code')}</Form.Label>
                                                    <Form.Control disabled={emptyToken ? false : true} required className="nsu-form-control" type="text" name="remindercode" value={passwordData.remindercode} onChange={handleFormChange} />
                                                    <Form.Control.Feedback type="invalid">
                                                        {t('validText')}
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </Col>
                                            <Col lg={12} md={12} sm={12} xs={12}>
                                                <Form.Group className="mb-3" as={Col} controlId="password">
                                                    <Form.Label>{t('Password')}</Form.Label>
                                                    <Form.Control required className="nsu-form-control" type="password" name="password" value={passwordData.password} onChange={handleFormChange} />
                                                    <Form.Control.Feedback type="invalid">
                                                        {t('validPassword')}
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </Col>
                                            <Col lg={12} md={12} sm={12} xs={12}>
                                                <Form.Group className="mb-3" as={Col} controlId="password_repeat">
                                                    <Form.Label>{t('password_repeat')}</Form.Label>
                                                    <Form.Control required className="nsu-form-control" type="password" name="password_repeat" value={passwordData.password_repeat} onChange={handleFormChange} />
                                                    <Form.Control.Feedback type="invalid">
                                                        {t('password_repeatPlaceholder')}
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Row className="top-margin">
                                            <Col lg={6} md={12} sm={12} xs={12}>
                                            { inAction && 
                                                        <Spinner
                                                        as="span"
                                                        animation="border"
                                                        size="sm"
                                                        role="status"
                                                        aria-hidden="true" /> 
                                            }
                                                <Button disabled={inAction} type="submit" className="btn btn-nsu" variant="danger">{t('changePasswordBtn')}</Button>
                                            </Col>
                                            <Col lg={6} md={12} sm={12} xs={12}>
                                                <Button disabled={inAction} type="button" className="btn btn-nsu" variant="light" onClick={onBack}>{t('Megsem')}</Button>
                                            </Col>
                                        </Row>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </Form>
                </Col>
            </Row>
        </Container>
    );
};

export default PasswordChange;