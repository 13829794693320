import { Container, Navbar, Figure, Dropdown, NavDropdown } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import UserService from "../../services/User.service";
import UserModel from "../../models/user.model";
import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import CartButton from "../common/CartButton";
import config from "../../config.json";

const Menu = () => {
    const defaultLanguage = navigator.language && navigator.language.length > 0 ? navigator.language.substring(0,2) : config.DEFAULT_LANGUAGE;
    const { t, i18n } = useTranslation();
    const [userFullname, setUserFullname] = useState('');
    const [userEmail, setUserEmail] = useState('');
    const [language, setLanguage] = useState(defaultLanguage);
    const userService = new UserService();
    const [minimalData, setMinimalData] = useState(false);

    const updateHeader = ((modifiedUserData: any) => {
        const name = modifiedUserData && 'lastname' in modifiedUserData && modifiedUserData.lastname !== undefined ? modifiedUserData.lastname : t('guestUserName');
        setUserFullname(name);
        const email = modifiedUserData && 'email' in modifiedUserData && modifiedUserData.email !== undefined ? modifiedUserData.email : t('defaultEmailPlaceholder');
        setUserEmail(email);
    });

    useEffect(() => {
        const lang = localStorage.getItem('language');
        let parsendLanguage = '';
        if (lang && lang !== '') {
            parsendLanguage = JSON.parse(lang);
        }
        else {
            parsendLanguage = defaultLanguage;
        }
        setLanguage(parsendLanguage && parsendLanguage !== '' ? parsendLanguage : defaultLanguage);
        i18n.changeLanguage(parsendLanguage && parsendLanguage !== '' ? parsendLanguage : defaultLanguage);
        const user = userService.getUser() as UserModel;
        const name = user && 'lastname' in user && user.lastname !== undefined ? user.lastname : t('guestUserName');
        setUserFullname(name);
        const email = user && 'email' in user && user.email !== undefined ? user.email : t('defaultEmailPlaceholder');
        setUserEmail(email);
        setMinimalData(userService.checkForUserMinimalInformations(user));
        window.addEventListener('userDataChange', (event) => updateHeader(event));
    }, []);

    useEffect(() => {
        i18n.changeLanguage(language);
        localStorage.setItem('language',JSON.stringify(language));
    }, [language]);

    return (
        <>
            <Navbar bg="dark" data-bs-theme="dark">
                <Container data-bs-theme="dark">
                    <Navbar.Brand href="/dashboard"><Figure className="logo-small"></Figure></Navbar.Brand>
                    <Navbar.Collapse className="justify-content-end">
                        <Navbar.Text>
                            {t('welcomeUser')}{userFullname}
                            <small className="header-email">{userEmail}</small>
                        </Navbar.Text>
                        <NavDropdown title={t(language)} id="basic-nav-dropdown" className="language-selector">
                            <NavDropdown.Item href="#" onClick={() => setLanguage('hu')}>{t('Magyar')}</NavDropdown.Item>
                            <NavDropdown.Item href="#" onClick={() => setLanguage('en')}>{t('English')}</NavDropdown.Item>
                        </NavDropdown>
                        <Dropdown>
                            <Dropdown.Toggle variant="success" id="dropdown-basic">
                                <FontAwesomeIcon icon={faBars} />
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                            { minimalData && 
                            <>
                                <Dropdown.Item href="/places">{t('Jegyvásárlás')}</Dropdown.Item>
                                <Dropdown.Item href="/tickets">{t('Jegyeim')}</Dropdown.Item>
                                <Dropdown.Item href="/reservations">{t('Medence foglaltságok')}</Dropdown.Item>
                                <Dropdown.Item href="/settings">{t('Beállítások')}</Dropdown.Item>
                            </>
                            }
                                <Dropdown.Item href="/profile">{t('Profilom')}</Dropdown.Item>
                                <Dropdown.Item href="/logout">{t('menuLogout')}</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
            <CartButton></CartButton>
        </>
    );
};

export default Menu;