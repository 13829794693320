import { Container, Row, Col, Card } from "react-bootstrap";
import Menu from "../layouts/Menu";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCartShopping, faCogs, faStore, faSwimmingPool, faTicket, faUserCog } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";

const Dashboard = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    document.body.className='';

    return (
        <>
            <Menu />
            <Container>
                <Row className="content">
                    <Col lg={12} md={12} sm={12} xs={12}>
                        <Row className="top-spaced">
                            <Col lg={4} md={6} sm={12} xs={12}>
                                <Card className="dashboard-box" onClick={() => navigate('/places')}>
                                    <Card.Body>
                                        <FontAwesomeIcon icon={faStore} className="dashboard-icon"></FontAwesomeIcon>
                                        <h2>{t('Jegyvásárlás')}</h2>
                                    </Card.Body>
                                    <Card.Footer className="dashboard-over"></Card.Footer>
                                </Card>
                            </Col>
                            <Col lg={4} md={6} sm={12} xs={12}>
                                <Card className="dashboard-box" onClick={() => navigate('/tickets')}>
                                    <Card.Body>
                                        <FontAwesomeIcon icon={faTicket} className="dashboard-icon"></FontAwesomeIcon>
                                        <h2>{t('Jegyeim')}</h2>
                                    </Card.Body>
                                    <Card.Footer className="dashboard-over"></Card.Footer>
                                </Card>
                            </Col>
                            <Col lg={4} md={6} sm={12} xs={12}>
                                <Card className="dashboard-box" onClick={() => navigate('/order')}>
                                    <Card.Body>
                                        <FontAwesomeIcon icon={faCartShopping} className="dashboard-icon"></FontAwesomeIcon>
                                        <h2>{t('Kosaram')}</h2>
                                    </Card.Body>
                                    <Card.Footer className="dashboard-over"></Card.Footer>
                                </Card>
                            </Col>
                        </Row>
                        <Row className="top-spaced">
                            <Col lg={4} md={6} sm={12} xs={12}>
                                <Card className="dashboard-box" onClick={() => { navigate('/profile') }}>
                                    <Card.Body>
                                        <FontAwesomeIcon icon={faUserCog} className="dashboard-icon"></FontAwesomeIcon>
                                        <h2>{t('Profilom')}</h2>
                                    </Card.Body>
                                    <Card.Footer className="dashboard-over"></Card.Footer>
                                </Card>
                            </Col>
                            <Col lg={4} md={6} sm={12} xs={12}>
                                <Card className="dashboard-box" onClick={() => navigate('/reservations')}>
                                    <Card.Body>
                                        <FontAwesomeIcon icon={faSwimmingPool} className="dashboard-icon"></FontAwesomeIcon>
                                        <h2>{t('Medence foglaltságok')}</h2>
                                    </Card.Body>
                                    <Card.Footer className="dashboard-over"></Card.Footer>
                                </Card>
                            </Col>
                            <Col lg={4} md={6} sm={12} xs={12}>
                                <Card className="dashboard-box" onClick={() => navigate('/profile')}>
                                    <Card.Body>
                                        <FontAwesomeIcon icon={faCogs} className="dashboard-icon"></FontAwesomeIcon>
                                        <h2>{t('Beállítások')}</h2>
                                    </Card.Body>
                                    <Card.Footer className="dashboard-over"></Card.Footer>
                                </Card>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </>
    );
};

export default Dashboard;