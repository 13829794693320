import axios from "axios";
import config from "../config.json";

class CartService {

    private endpointPrefix = config.API.URL + '/payment';
    private headerConfig = {
        'Content-Type': 'application/json'
    };

    public startPayment(entityID: string) {
        return new Promise<any>((resolve, reject) => {
            axios.get(this.endpointPrefix + '/list/' + entityID).then((response: any) => {
                resolve(response);
            }).catch((error) => {
                resolve(error);
            });
        });
    }

    public getCart() {
        return new Promise<any>((resolve, reject) => {
            const cart = localStorage.getItem('cart');
            resolve(cart ? JSON.parse(cart) : {});
        });
    }

}

export default CartService;