import { useEffect, useState } from "react";
import { Col, Row, Form, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import BilliingAddressModel from '../../models/billingaddress.model';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";


const BilliingAddress = (props: any) => {
    const { t } = useTranslation();
    const [address, setAddress] = useState<BilliingAddressModel>();

    document.body.className='';

    useEffect(() => {
        setAddress(props.address);
    }, [props]);

    const handleFormChange = (event: any) => {
        const { name, value } = event.target;
        setAddress({...address as BilliingAddressModel, [name]: value });
    };

    const emitNewData = () => {
        props.onAddressDataChanged(address, props.index);
    };

    const deleteAddress = () => {
        props.onDeleteAddress(props.index);
    };

    return(
        <>
            {props.index > 0 &&
            <Row>
                <Col lg={12} md={6} sm={12} xs={12} className="align-top right-position">
                    <Button type="button" onClick={deleteAddress} className="btn color-danger">
                        <FontAwesomeIcon icon={faTrash}></FontAwesomeIcon>
                    </Button>
                </Col>
            </Row>
            }
            <Row>
                {props.index > 0 && 
                <Col lg={12} md={6} sm={12} xs={12} className="align-top">
                    <Form.Group className="mb-3" controlId="billingname">
                        <Form.Label>{t('billingname')}</Form.Label>
                        <Form.Control placeholder={t('billingname')} autoComplete="new-password" className="nsu-form-control" type="text" name="billingname" value={address?.billingname} onChange={handleFormChange} onBlur={emitNewData} />
                    </Form.Group>
                </Col>
                }
            </Row>
            <Row>
                {props.index > 0 && 
                <Col lg={3} md={6} sm={12} xs={12} className="align-top">
                    <Form.Group className="mb-3" controlId="taxnumber">
                        <Form.Label>{t('taxnumber')}</Form.Label>
                        <Form.Control placeholder={t('taxnumber')} autoComplete="new-password" className="nsu-form-control" type="text" name="taxnumber" value={address?.taxnumber} onChange={handleFormChange} onBlur={emitNewData} />
                    </Form.Group>
                </Col>
                }
                <Col lg={3} md={6} sm={12} xs={12} className="align-top">
                    <Form.Group className="mb-3" controlId="billingzip">
                        <Form.Label>{t('billingzip')}</Form.Label>
                        <Form.Control required placeholder={t('billingzip')} autoComplete="new-password" className="nsu-form-control" type="text" name="billingzip" value={address?.billingzip} onChange={handleFormChange} onBlur={emitNewData} />
                        <Form.Control.Feedback type="invalid">
                            {t('validText')}
                        </Form.Control.Feedback>
                    </Form.Group>
                </Col>
                <Col lg={6} md={6} sm={12} xs={12} className="align-top">
                    <Form.Group className="mb-3" controlId="billingcity">
                        <Form.Label>{t('billingcity')}</Form.Label>
                        <Form.Control required placeholder={t('billingcity')} autoComplete="new-password" className="nsu-form-control" type="text" name="billingcity" value={address?.billingcity} onChange={handleFormChange} onBlur={emitNewData} />
                        <Form.Control.Feedback type="invalid">
                            {t('validText')}
                        </Form.Control.Feedback>
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col lg={12} md={12} sm={12} xs={12} className="align-top">
                    <Form.Group className="mb-3" controlId="billingaddress">
                        <Form.Label>{t('billingaddress')}</Form.Label>
                        <Form.Control required placeholder={t('billingaddress')} autoComplete="new-password" className="nsu-form-control" type="text" name="billingaddress" value={address?.billingaddress} onChange={handleFormChange} onBlur={emitNewData} />
                        <Form.Control.Feedback type="invalid">
                            {t('validText')}
                        </Form.Control.Feedback>
                    </Form.Group>
                </Col>
            </Row>
        </>
    );
};

export default BilliingAddress;