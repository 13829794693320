import axios from "axios";
import config from "../config.json";

class DataService {

    private endpointPrefix = config.API.URL + '/data';
    private headerConfig = {
        'Content-Type': 'application/json'
    };

    public getTitles() {
        return new Promise<any>((resolve, reject) => {
            axios.get(this.endpointPrefix + '/titles').then((response: any) => {
                resolve(response);
            }).catch((error) => {
                resolve(error);
            });
        });
    }

    public getGenders() {
        return new Promise<any>((resolve, reject) => {
            axios.get(this.endpointPrefix + '/genders').then((response: any) => {
                resolve(response);
            }).catch((error) => {
                resolve(error);
            });
        });
    }

    public getCountries() {
        return new Promise<any>((resolve, reject) => {
            axios.get(this.endpointPrefix + '/countries?lang=hu').then((response: any) => {
                resolve(response);
            }).catch((error) => {
                resolve(error);
            });
        });
    }

    public getInstitutions() {
        return new Promise<any>((resolve, reject) => {
            axios.get(this.endpointPrefix + '/institutions').then((response: any) => {
                resolve(response);
            }).catch((error) => {
                resolve(error);
            });
        });
    }

    public getLocationNameByEntity(entityID: string) {
        return new Promise<any>((resolve, reject) => {
            this.getInstitutions().then((response: any) => {
                const institutions = response.data;
                const institution = institutions.find((i: any) => i.id === entityID);
                resolve(institution? institution.label : '');
            });
        });
    }
}

export default DataService;