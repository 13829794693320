import { Row, Col, Accordion, Spinner } from "react-bootstrap";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ReservationsService from "../../services/ReservationsService";
import UserModel from "../../models/user.model";
import AreaModel from "../../models/area.model";
import TimeTableModel from '../../models/timetable.model';
import UserService from "../../services/User.service";
import ReservationGraph from "./ReservationGraph";

const ReservationList = (props: any) => {
    const userService = new UserService();
    const reservationsService = new ReservationsService();

    const { t } = useTranslation();
    const [userData, setUserData] = useState<UserModel>();
    const [placeID, setPlaceID] = useState('');
    const [areas, setAreas] = useState<AreaModel[]>([]);
    const [activeArea, setActiveArea] = useState('');
    const [inAction, setInAction] = useState(false);
    const [currentWeekDays, setCurrentWeekDays] = useState<any[]>([]);
    const [currentDay, setCurrentDay] = useState('');
    const [timeTable, setTimeTable] = useState<TimeTableModel>();
    const months = [
        "január", 
        "február", 
        "március", 
        "aprilis", 
        "május", 
        "június", 
        "július", 
        "augusztus", 
        "szeptember", 
        "október", 
        "november", 
        "december"
    ];

    useEffect(() => {
        setCurrentDay(new Date().toISOString().split('T')[0]);
        const user: UserModel = userService.getUser() as UserModel;
        setUserData(user);
        setPlaceID(props.placeID);
        loadAreas(props.placeID);
        getCurrentWeekDays();
    },[]);

    useEffect(() => {
        loadTimeTables();
    },[activeArea, currentDay]);

    const loadAreas = async (placeid: string) => {
        setInAction(true);
        reservationsService.getAreas(placeid).then((response: any) => {
            if ('status' in response) {
                if (response.status === 200) {
                    const areasList = Object.keys(response.data).map(key => ({
                        id: key,
                        label: response.data[key]
                    }));
                    setAreas(areasList);
                    setActiveArea(areasList[0].id);
                }
            }
            setInAction(false);
        });
        setInAction(false);
    };

    const loadTimeTables = () => {
        setInAction(true);
        if (placeID && activeArea && currentDay) {
            const resetTimeTables: TimeTableModel = {area_id: ''};
            setTimeTable(resetTimeTables);
            reservationsService.getTimeTables(placeID, activeArea, currentDay).then((response: any) => {
                if ('status' in response) {
                    if (response.status === 200) {
                        setTimeTable(response.data);
                    }
                    else {
                        setTimeTable({});
                    }
                }
                setInAction(false);
            });
        }
        setInAction(false);
    };

    const changeAreaView = (eventKey: any) => {
        if (eventKey) {
            setActiveArea(eventKey);
        }
    };

    const showDay = (day: string) => {
        setCurrentDay(day);
    };

    const getCurrentWeekDays = () => {
        const current = new Date();
    
        const startOfWeek = new Date(current);
        const dayOfWeek = current.getDay();
        const diff = (dayOfWeek === 0 ? -6 : 1) - dayOfWeek;
        startOfWeek.setDate(startOfWeek.getDate() + diff);
    
        const weekDates = [];
        
        for (let i = 0; i < 7; i++) {
            const day = new Date(startOfWeek);
            day.setDate(startOfWeek.getDate() + i);
            const formattedDate = day.toISOString().split('T')[0];
            const dayOfMonth = formattedDate.slice(-2);
            const dayOfWeek = day.toLocaleDateString('hu-HU', { weekday: 'short' });
            const dayObject = {
                date: formattedDate,
                dayofmonth: dayOfMonth,
                dayofweek: dayOfWeek
            };
            weekDates.push(dayObject);
        }
        setCurrentWeekDays(weekDates);
    }

    return (
        <>
            {areas.length === 0 && !inAction ? 
                <Row>
                    <Col lg={12} className="centered">
                        <Spinner animation="border" variant="danger" />
                        <p>{t('noAreas')}</p>
                    </Col>
                </Row>
            : 
            <>
                <Row className="bottom-margin">
                    <Col lg={3} md={3} sm={12} xs={12}>
                        <span className="calendar-year">{new Date().getFullYear()}</span>
                        <span className="calendar-month">{months[new Date().getMonth()]}</span>
                    </Col>
                    <Col lg={9} md={9} sm={12} xs={12}>
                        <table className="calendar-week">
                            <tr>
                                {currentWeekDays.map((day: any) => (
                                <td className={currentDay === day.date ? 'active-day' : ''} onClick={() => {showDay(day.date)}}>
                                    <span className="calendar-dayname">{day.dayofweek}</span>
                                    <span className="calendar-day">{day.dayofmonth}</span>
                                </td>
                                ))}
                            </tr>
                        </table>
                    </Col>
                </Row>
                <Row>
                    <Col lg={12} md={12} sm={12} xs={12}>
                        <Accordion onSelect={changeAreaView}>
                            {areas.map((area: AreaModel) => (
                            <Accordion.Item eventKey={area.id ? area.id : ''}>
                                <Accordion.Header>{area.label ? area.label : ''}</Accordion.Header>
                                <Accordion.Body>
                                    {timeTable?.area_id === '' && !inAction ? 
                                    <Row>
                                        <Col lg={12} className="centered">
                                            <Spinner animation="border" variant="danger" />
                                            <p>{t('noAreas')}</p>
                                        </Col>
                                    </Row>
                                    :
                                    <ReservationGraph reservationData={timeTable} day={currentDay}></ReservationGraph>
                                    }
                                </Accordion.Body>
                            </Accordion.Item>
                            ))}
                        </Accordion>
                    </Col>
                </Row>
            </>
            }
        </>
    );
};

export default ReservationList;