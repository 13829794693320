import { useState } from "react";
import { Col, Container, Row, Card, Form, Button, Figure, Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import UserModel from '../../../models/user.model';
import LoginModel from '../../../models/login.model';
import { useNavigate } from "react-router-dom";
import UserService from '../../../services/User.service';
import { toast, ToastContainer } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";

const Login = () => {
    const { t } = useTranslation();
    const userService = new UserService();
    const [loginData, setLoginData] = useState<LoginModel>({});
    const [validated, setValidated] = useState(false);
    const [inAction, setInAction] = useState(false);
    const [hidePass, setHidePass] = useState(true);
    const navigate = useNavigate();

    document.body.className='authBody';

    const onLogin = async (event: any) => {
        setInAction(true);
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            setValidated(true);
            setInAction(false);
        }
        else {
            userService.authenticateUser(loginData).then((response: any) => {
                if ('status' in response) {
                    if (response.status === 200) {
                        toast.success(t('successfullLogin'));
                        const userID: number = response.data.data.id;
                        userService.getUserData(userID).then((responseData: any) => {
                            if ('status' in responseData) {
                                if (responseData.status === 200) {
                                    let userData: UserModel = responseData.data.data;
                                    userData.SMB = response.data.data.api_key;
                                    localStorage.setItem('userData', JSON.stringify(userData));
                                    const timer = setTimeout(() => {
                                        window.location.href = '/dashboard';
                                        clearTimeout(timer);
                                    }, 1000);
                                }
                                else {
                                    toast.error(t('errorAuthentication'));
                                    setInAction(false);
                                }
                            }
                        });
                    }
                    else {
                        toast.error(t('errorLogin'));
                        setInAction(false);
                    }
                }
            });
        }
        event.preventDefault();
        event.stopPropagation();
    };

    const handleFormChange = (event: any) => {
        const { name, value } = event.target;
        setLoginData({...loginData, [name]: value });
    };

    const onRegister = () => {
        navigate('/registration');
    };

    const onReminder = () => {
        navigate('/reminder');
    };

    return (
        <Container>
            <ToastContainer />
            <Row className="content">
                <Col lg={4} md={6} sm={12} xs={12}>
                    <Form noValidate validated={validated} onSubmit={onLogin}>
                        <Row>
                            <Col lg={12} md={12} sm={12} xs={12}>
                                <Card className="login">
                                    <Card.Body>
                                        <Row>
                                            <Col lg={12} md={12} sm={12} xs={12}>
                                                <Figure className="logo"></Figure>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col lg={12} md={12} sm={12} xs={12}>
                                                <h1>{t('focimBejelentkezes')}</h1>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col lg={12} md={12} sm={12} xs={12}>
                                                <Form.Group className="mb-3" controlId="email">
                                                    <Form.Control required placeholder={t('Email')} className="nsu-form-control" type="text" name="email" value={loginData.email} onBlur={userService.chekcEmailValidity} onChange={handleFormChange} />
                                                    <Form.Control.Feedback type="invalid">
                                                        {t('validEmail')}
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </Col>

                                            <Col lg={12} md={12} sm={12} xs={12}>
                                                <Form.Group className="mb-3" as={Col} controlId="password">
                                                    <Form.Control required placeholder={t('Password')} className="nsu-form-control" type={hidePass ? 'password' : 'text'} name="password" value={loginData.password} onChange={handleFormChange} />
                                                    <FontAwesomeIcon className="show-password" icon={hidePass ? faEye : faEyeSlash} onClick={() => {setHidePass(!hidePass)}} />
                                                    <Form.Control.Feedback type="invalid">
                                                        {t('validPassword')}
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col lg={12} md={12} sm={12} xs={12}>
                                                <Button type="submit" className="btn btn-nsu color-danger" disabled={inAction}>
                                                    { inAction && 
                                                        <Spinner
                                                        as="span"
                                                        animation="border"
                                                        size="sm"
                                                        role="status"
                                                        aria-hidden="true" /> 
                                                    } {t('Belepes')}
                                                </Button>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col lg={4} md={4} sm={4} xs={4}>
                                                <div className="divider-line"></div>
                                            </Col>
                                            <Col lg={4} md={4} sm={4} xs={4}>
                                                <div className="divider-text">{t('vagy')}</div>
                                            </Col>
                                            <Col lg={4} md={4} sm={4} xs={4}>
                                                <div className="divider-line"></div>
                                            </Col>
                                        </Row>
                                        <Row className="top-max-margin">
                                            <Col lg={12} md={12} sm={12} xs={12}>
                                                <Button type="button" className="btn btn-nsu color-default" onClick={onReminder}>{t('Elfelejtett jelszó')}</Button>
                                            </Col>
                                        </Row>
                                        <Row className="top-min-margin">
                                            <Col lg={12} md={12} sm={12} xs={12}>
                                                <Button type="button" className="btn btn-nsu color-default" onClick={onRegister}>{t('Regisztracio')}</Button>
                                            </Col>
                                        </Row>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </Form>
                </Col>
            </Row>
        </Container>
    );
};

export default Login;