import { faCartShopping, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useReducer, useState } from "react";
import { Modal, Button, Row, Col, Card, ToastContainer, Container, Spinner, Badge } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import CartService from "../../services/Cart.service";
import ProductService from "../../services/Product.service";
import CartModel from "../../models/cart.model";
import Menu from "../layouts/Menu";
import { useNavigate } from "react-router-dom";
import UserDetails from "../Profile/UserDetails";
import eventBus from "../../services/EventBus";

const CartButton = (props: any) => {
    const { t } = useTranslation();
    const [show, setShow] = useState(false);
    const [modal, setModal] = useState(true);
    const [cart, setCart] = useState<CartModel>({cart: [], total: 0});
    const [inAction, setInAction] = useState(false);
    const [cartItemsNum, setCartItemsNum] = useState(0);
    const cartService = new CartService();
    const productService = new ProductService();
    const navigate = useNavigate();

    useEffect(() => {
        if (props.type) {
            const type = props.type === 'details' ? false : true;
            setModal(type);
        }
        reloadCart();

        eventBus.on('cartUpdate', (payload: any) => {
            reloadCart();
        });
    },[]);

    const reloadCart = () => {
        cartService.getCart().then((cartData: CartModel) => {
            if (cartData && cartData.cart) {
                setCartItemsNum(cartData.cart?.length);
            }
            setCart(cartData);
        });
    };

    const handleShow = () => {
        reloadCart();
        setShow(true);
    };

    const handleClose = () => setShow(false);

    const deleteProductFromCart = (index: number) => {
        const originalCart = {...cart};
        if (originalCart && originalCart.cart) {
            originalCart.cart?.splice(index, 1);
            originalCart.total = originalCart?.cart.reduce((acc: number, curr: any) => acc + (curr.price * curr.quantity), 0);
            setCart(originalCart);
            localStorage.setItem('cart', JSON.stringify(originalCart));
            reloadCart();
        }
    };

    const orderPage = () => {
        navigate('/order');
    };

    const startPayment = () => {
        navigate('/startpayment');
    };

    const keepOnShopping = () => {
        navigate('/places');
    };

    return (
        <>
            {modal ? (
            <>
                <div className="cart-btn" onClick={handleShow}>
                    <FontAwesomeIcon icon={faCartShopping} />
                    {cartItemsNum > 0 &&
                        <Badge bg="danger" pill={true} className="cart-number">{cartItemsNum}</Badge>
                    }
                </div>
                <Modal show={show} onHide={handleClose} dialogClassName="modal-right">
                    <Modal.Header>
                        <h4>{t('Kosár tartalma')}</h4>
                    </Modal.Header>
                    <Modal.Body className="scrollableVertical">
                        {cart.cart && cart.cart.map((product: any, index: number) => (
                            <p key={product.id} className="cart-item">
                                {product.label} <small className="productLocation">({product.location})</small>
                                <a className="link trash" onClick={() => deleteProductFromCart(index)}><FontAwesomeIcon icon={faTrash} /></a>
                                <br />
                                {product.quantity} {t('darab')} <small>{productService.formatCurrency(product.price)} / {t('darab')}</small> 
                                <span>{productService.formatCurrency((product.price * product.quantity).toString())}</span>
                                <hr />
                            </p>
                        ))}
                        <h4 className="sum-price">{t('Összesen')}: {productService.formatCurrency(cart.total ? cart.total.toString() : '0')}</h4>
                    </Modal.Body>
                    <Modal.Footer>
                        <a className="link" onClick={handleClose}>{t('Tovább vásárolok')}</a>
                        <Button type="button" onClick={orderPage} className="btn btn-middle-nsu color-danger" disabled={inAction || cart.cart?.length === 0}>
                            {t('Megrendelem')}
                        </Button>
                    </Modal.Footer>
                </Modal>
            </>
            ) : (
            <>
                <ToastContainer />
                <Menu />
                <Container>
                    <Row className="content align-items-start">
                        <Col lg={3} md={6} sm={12} xs={12}>
                            <Card className="profile">
                                <Card.Header>
                                    {t('Kosár tartalma')}
                                </Card.Header>
                                <Card.Body>
                                    {cart.cart && cart.cart.map((product: any, index: number) => (
                                        <p key={product.id} className="cart-item">
                                            {product.label}
                                            <a className="link trash" onClick={() => deleteProductFromCart(index)}><FontAwesomeIcon icon={faTrash} /></a>
                                            <br />
                                            {product.quantity} {t('darab')} <small>{productService.formatCurrency(product.price)} / {t('darab')}</small> 
                                            <span>{productService.formatCurrency((product.price * product.quantity).toString())}</span>
                                            <hr />
                                        </p>
                                    ))}
                                    <h4>{t('Összesen')}: {productService.formatCurrency(cart.total ? cart.total.toString() : '0')}</h4>
                                    <Row>
                                        <Col lg={5} md={5} sm={12} xs={12} className="bottom-thin-spaced">
                                            <Button type="button" onClick={keepOnShopping} className="btn btn-none-nsu color-danger bordered" disabled={inAction}>
                                                {t('Vissza')}
                                            </Button>
                                        </Col>
                                        <Col lg={7} md={7} sm={12} xs={12} className="bottom-thin-spaced">
                                            <Button type="button" onClick={startPayment} className="btn btn-middle-nsu color-danger" disabled={inAction || cart.cart?.length === 0}>
                                                { inAction && 
                                                    <Spinner
                                                    as="span"
                                                    animation="border"
                                                    size="sm"
                                                    role="status"
                                                    aria-hidden="true" /> 
                                                } {t('orderFinishBtn')}
                                            </Button>
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col lg={9} md={6} sm={12} xs={12}>
                            <UserDetails readOnly={true} newBilling={false} />
                            <br />
                        </Col>
                    </Row>
                </Container>
            </>
            )
            }
        </>
    );
};

export default CartButton;