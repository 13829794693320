import { Row, Col, Accordion, Spinner } from "react-bootstrap";
import { useEffect, useReducer, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast, ToastContainer } from "react-toastify";
import ProductModel from '../../models/product.model';
import ProductService from "../../services/Product.service";
import UserModel from "../../models/user.model";
import CartModel from '../../models/cart.model';
import UserService from "../../services/User.service";
import CartService from "../../services/Cart.service";
import eventBus from "../../services/EventBus";

const ProductsList = (props: any) => {
    const productService = new ProductService();
    const userService = new UserService();
    const cartService = new CartService();
    const { t } = useTranslation();
    const minQuantity = 1;
    const minStep = 1;
    const [userData, setUserData] = useState<UserModel>();
    const [placeID, setPlaceID] = useState('');
    const [products, setProducts] = useState<ProductModel[]>([]);
    const [activeProduct, setActiveProduct] = useState('');
    const [productQuantity, setProductQuantity] = useState(1);
    const [inAction, setInAction] = useState(false);
    const [cart, setCart] = useState<CartModel>({ cart: [], total: 0});

    useEffect(() => {
        const user: UserModel = userService.getUser() as UserModel;
        setUserData(user);
        cartService.getCart().then((cartData: CartModel) => {
            setCart(cartData);
        });
        setPlaceID(props.placeID);
        loadProducts(props.placeID);
    },[]);

    const loadProducts = async (placeid: string) => {
        setInAction(true);
        productService.getProductsList(placeid).then((response: any) => {
            if ('status' in response) {
                if (response.status === 200) {
                    const prods = response.data.filter((prod: any) => prod.id !== null && prod.id !== undefined);
                    setProducts(prods);
                }
            }
            setInAction(false);
        });
        setInAction(false);
    };

    const changeProductView = (event: any) => {
    };

    const decrementQty = () => {
        setProductQuantity(productQuantity > minQuantity? productQuantity - minStep : productQuantity);
    };

    const incrementQty = () => {
        setProductQuantity(productQuantity + minStep);
    };

    const addToCart = (productItem: any) => {
        cartService.getCart().then((cartData: CartModel) => {
            let currentCart: CartModel = Object.entries(cart).length === 0 ? { cart: [], total: 0 } : cartData;
            if (productItem && currentCart && currentCart.cart) {
                const index = currentCart.cart.findIndex(termek => termek.id === productItem.id);
                if (index && index > -1 && currentCart.cart.length >= index && currentCart.cart[index].quantity) {
                    let prod = currentCart.cart[index];
                    if (prod && prod.quantity) {
                        prod.quantity += productQuantity;
                        currentCart.cart[index] = prod;
                    }
                }
                else {
                    productItem.quantity = productQuantity;
                    productItem['location'] = props.placeName;
                    currentCart.cart.push(productItem);
                }
                currentCart.total = currentCart.cart.reduce((acc: number, curr: any) => acc + (curr.price * curr.quantity), 0);
                localStorage.setItem('cart', JSON.stringify(currentCart));
                setCart(currentCart);
                setProductQuantity(1);
                toast.success(t('successfullyAddedToCart'));
                eventBus.dispatch("cartUpdate", { message: "cart updated" });
            }
            else {
                toast.error(t('errorAddedToCart'));
            }    
        });
    };

    return (
        <>
            <ToastContainer />
            {products.length > 0 ? 
                <Accordion defaultActiveKey={activeProduct} onSelect={changeProductView}>
                    {products.map((product: ProductModel) => (
                    <Accordion.Item eventKey={product.id ? product.id : ''}>
                        <Accordion.Header>{product.label ? product.label : ''}</Accordion.Header>
                        <Accordion.Body>
                            <Row>
                                <Col lg={5} md={12} sm={12} xs={12}>
                                    <p className="product-description">{product.description}</p>
                                </Col>
                                <Col lg={3} md={12} sm={12} xs={12}>
                                    <small className="product-price">{productService.formatCurrency(product.price ? product.price : '0')}</small>
                                </Col>
                                <Col lg={4} md={12} sm={12} xs={12} className="centered">
                                    <Row>
                                        <Col lg={4}>
                                            <button className="btn btn-inline-nsu" onClick={() => decrementQty()}>-</button>
                                        </Col>
                                        <Col lg={4}>
                                            <span className="product-qty">{productQuantity}</span>
                                        </Col>
                                        <Col lg={4}>
                                            <button className="btn btn-inline-nsu" onClick={() => incrementQty()}>+</button>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg={12} className="centered">
                                            <button className="btn btn-middle-nsu color-danger" onClick={() => addToCart(product)}>{t('AddToCartBtn')}</button>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Accordion.Body>
                    </Accordion.Item>
                    ))}
                </Accordion>
            : 
                <Row>
                    <Col lg={12} className="centered">
                        <Spinner animation="border" variant="danger" />
                        <p>{t('noProducts')}</p>
                    </Col>
                </Row>
            }
        </>
    );
};

export default ProductsList;