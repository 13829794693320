import axios from "axios";
import config from "../config.json";

import UserModel from "../models/user.model";
import LoginModel from '../models/login.model';
import PasswordModel from "../models/password.model";

class UserService {

    private endpointPrefix = config.API.URL + '/user';
    private headerConfig = {
        'Content-Type': 'application/json'
    };

    public registerUser(userdata: UserModel) {
        return new Promise<any>((resolve, reject) => {
            axios.post(this.endpointPrefix, userdata, {headers: this.headerConfig})
            .then((response: any) => {
                resolve(response);
            })
            .catch((error) => {
                resolve(error.response);
            });
        });
    }

    public checkConfirmationCode(userdata: UserModel) {
        return new Promise<any>((resolve, reject) => {
            axios.get(this.endpointPrefix + '/confirm/' + userdata.id + '/' + userdata.confirmationCode)
            .then((response: any) => {
                resolve(response);
            })
            .catch((error) => {
                resolve(error);
            });
        });
    }

    public authenticateUser(loginData: LoginModel) {
        return new Promise<any>((resolve, reject) => {
            axios.post(this.endpointPrefix + '/login', {
                email: loginData.email, 
                password: loginData.password
            }, {headers: this.headerConfig}).then((response: any) => {
                resolve(response);
            }).catch((error) => {
                resolve(error);
            });
        });
    }

    public getUser() {
        const userObj = localStorage.getItem('userData');
        if (userObj) {
            return JSON.parse(userObj);
        } else {
            return null;
        }
    }

    public getUserData(user_id: number) {
        return new Promise<any>((resolve, reject) => {
            axios.get(this.endpointPrefix + '/' + user_id).then((response: any) => {
                let data = JSON.stringify(response.data.data);
                data = data.replace(/billingName/g,'billingname')

                .replace(/billingAddress/g,'billingaddress')
                .replace(/billingCity/g,'billingcity')
                .replace(/billingZip/g,'billingzip')
                .replace(/taxNumber/g,'taxnumber');
                response.data.data = JSON.parse(data);
                resolve(response);
            }).catch((error) => {
                resolve(error);
            });
        });
    }

    public modifyUser(userdata: UserModel) {
        return new Promise<any>((resolve, reject) => {
            axios.put(this.endpointPrefix + '/' + userdata.id, userdata, {headers: this.headerConfig})
            .then((response: any) => {
                resolve(response);
            })
            .catch((error) => {
                resolve(error);
            });
        });
    }

    public confirmUser(confirm_code: string) {
        return new Promise<any>((resolve, reject) => {
            axios.get(this.endpointPrefix + '/confirm/?confirmationcode=' + confirm_code).then((response: any) => {
                resolve(response);
            }).catch((error) => {
                reject(error);
            });
        });
    }

    public forgottenPassword(userData: UserModel) {
        return new Promise<any>((resolve, reject) => {
            axios.post(this.endpointPrefix + '/passwordreminder', { email: userData.email }, {headers: this.headerConfig})
            .then((response: any) => {
                resolve(response);
            })
            .catch((error) => {
                resolve(error);
            });
        });
    }

    public changePassword(passwordData: PasswordModel) {
        return new Promise<any>((resolve, reject) => {
            axios.post(this.endpointPrefix + '/changepassword/' + passwordData.remindercode, { 
                password: passwordData.password,
                password_confirm: passwordData.password_repeat
            })
            .then((response: any) => {
                resolve(response);
            })
            .catch((error) => {
                reject(error);
            });
        });
    }

    public chekcEmailValidity(event: any) {
        const email = event.target.value;
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (emailRegex.test(email)) {
            event.target.setCustomValidity('');
        }
        else {
            event.target.setCustomValidity('invalid');
        }
    }

    public checkPasswordComplexity(event: any) {
        const password = event.target.value;
        let complexity = 0;
        if (password.length >= 10) {
            complexity++;
        }
        if (/[a-z]/.test(password)) {
            complexity++;
        }
        if (/[A-Z]/.test(password)) {
            complexity++;
        }
        if (/\d/.test(password)) {
            complexity++;
        }
        if (/[^a-zA-Z0-9]/.test(password)) {
            complexity++;
        }
        if (complexity >= 3) {
            event.target.setCustomValidity('');
        }
        else {
            event.target.setCustomValidity('invalid');
        }
    }

    public checkForUserMinimalInformations(user: UserModel) {
        return (user.id === 0 || 
            user.firstname === '' || 
            user.lastname === '' || 
            user.email === '' || 
            user.phone === '' || 
            user.gender === '' || 
            user.firstname === null || 
            user.lastname === null || 
            user.email === null || 
            user.phone === null || 
            user.gender === null || 
            user.id === undefined || 
            user.firstname === undefined || 
            user.lastname === undefined || 
            user.email === undefined || 
            user.phone === undefined || 
            user.gender === undefined) ? false : true;
    }

    public hideText(text: string, maskCharacter: string) : string {
        let maskedText = '';
        for (let i = 0; i < text.length; i++) {
            maskedText += '*';
        }
        return maskedText;
    }

}

export default UserService;