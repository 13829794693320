import { Row, Col, Container, Card, Spinner } from "react-bootstrap";
import Menu from "../layouts/Menu";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import PaymentService from "../../services/Payment.service";
import { useTranslation } from "react-i18next";
import eventBus from "../../services/EventBus";
import { useNavigate } from "react-router-dom";

const PaymentCheck = () => {
    const { transactionID } = useParams();
    const [trid, setTrid] = useState('');
    const [inAction, setInAction] = useState(false);
    const [paymentStatus, setPaymentStatus] = useState('');
    const paymentService = new PaymentService();
    const { t } = useTranslation();
    const navigate = useNavigate();

    useEffect(() => {
        if (transactionID) {
            setInAction(true);
            setTrid(transactionID);
            const userJSON = localStorage.getItem('userData');
            const userObj = userJSON? JSON.parse(userJSON) : {};    
            var timer = window.setTimeout(() => {
                paymentService.checkPaymentStatus(transactionID, userObj).then((response: any) => {
                    if (response.status === 200) {
                        if (response.data.message === 'SUCCESS') {
                            setPaymentStatus('success');
                            localStorage.removeItem('cart');
                            eventBus.dispatch("cartUpdate", { message: "cart updated" });
                            let redirectTimer = window.setTimeout(() => {
                                eventBus.dispatch("ticketsUpdate", { message: "Tickets list updated" });
                                navigate('/tickets');
                                clearTimeout(redirectTimer);
                            }, 2000);
                        }
                        else {
                            setPaymentStatus('failed');
                        }
                        setInAction(false);
                        clearTimeout(timer);
                    }
                    else {
                        console.log('Payment check error...');
                    }
                });
            }, 2000);
        }
    },[]);

    return (
        <>
            <Menu />
            <Container>
                <Row className="content">
                    <Col lg={12} md={12} sm={12} xs={12}>
                        <Row className="top-spaced">
                            <Col lg={12} md={12} sm={12} xs={12}>
                                <Card>
                                    <Card.Body>
                                        { inAction ?
                                            <Row>
                                                <Col lg={12} md={12} sm={12} xs={12}>
                                                    <h3 className="paymentTitle">
                                                        <Spinner
                                                        as="span"
                                                        animation="border"
                                                        variant="success"
                                                        role="status"
                                                        aria-hidden="true" className="rightSpaced" /> 
                                                        Várakozás a fizetés befejezésére...
                                                    </h3>
                                                    <p>
                                                        <span className="paymentInfoText">A fizetés befejezéséhez a bank jóváhagyására várunk, kérjük ne zárja be a böngészőt és ne frissítse az oldalt!</span>
                                                    </p>
                                                </Col>
                                            </Row>
                                        :
                                        <Row>
                                            <Col lg={12} md={12} sm={12} xs={12}>
                                                <h3 className={'paymentTitle ' + paymentStatus}>{t(paymentStatus)}</h3>
                                                <p>{t('Tranzakció azonosítója')}: {trid}</p>
                                                <p>{t('Dátum')}: {new Date().toISOString()}</p>
                                            </Col>
                                        </Row>
                                        }
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </>
    );
};

export default PaymentCheck;