import { useState } from "react";
import { Col, Container, Row, Card, Form, Button, Figure, Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import UserModel from '../../../models/user.model';
import { useNavigate } from "react-router-dom";
import UserService from '../../../services/User.service';
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

const Register = () => {
    const { t } = useTranslation();
    const userService = new UserService();
    const [userData, setUserData] = useState<UserModel>();
    const [validated, setValidated] = useState(false);
    const [inAction, setInAction] = useState(false);
    const navigate = useNavigate();

    document.body.className='authBody';

    const onSendRegister = (event: any) => {
        setInAction(true);
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            setValidated(true);
            setInAction(false);
        }
        else {
            userService.registerUser(userData as UserModel).then((reponse: any) => {
                if ('status' in reponse) {
                    switch (reponse.status) {
                        case 200:
                            toast.success(t('regisztracioSikeres'));
                            let modifiedUserData: UserModel = userData as UserModel;
                            modifiedUserData.id = reponse.data.data.id;
                            modifiedUserData.SMB = reponse.data.data.api_key;
                            setUserData(modifiedUserData);
                            localStorage.setItem('userData', JSON.stringify(modifiedUserData));
                            const timer = setTimeout(() => {
                                navigate('/confirmation');
                                clearTimeout(timer);
                            }, 2000);
                        break;
                        default:
                            if (reponse.data.message[0] === 'Email address already exists') {
                                toast.error(t('Email address already exists'));
                            }
                            else {
                                toast.error(t('hibaRegisztracioSikertelen'));
                            }
                            setInAction(false);
                        break;
                    }
                }
            });
        }
        event.preventDefault();
        event.stopPropagation();
    };

    const handleFormChange = (event: any) => {
        const { name, value } = event.target;
        setUserData({...userData as UserModel, [name]: value });
    };

    const onSubscribeNewsletter = (event: any) => {
        const name = event.target.name;
        const status = event.target.checked;
        setUserData({...userData as UserModel, [name]: status });
    }

    const checkPasswordSame = (event: any) => {
        const pwd = event.target.value;
        if (pwd !== userData?.password) {
            event.target.setCustomValidity('invalid');
        }
        else {
            event.target.setCustomValidity('');
        }
    };

    return (
        <Container>
            <ToastContainer />
            <Row className="content">
                <Col lg={4} md={6} sm={12} xs={12}>
                    <Form noValidate validated={validated} onSubmit={onSendRegister}>
                        <Row>
                            <Col lg={12} md={12} sm={12} xs={12}>
                                <Card className="login">
                                    <Card.Body>
                                        <Row>
                                            <Col lg={12} md={12} sm={12} xs={12}>
                                                <Figure className="logo"></Figure>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col lg={12} md={12} sm={12} xs={12}>
                                                <h1>{t('focimRegisztracio')}</h1>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col lg={12} md={12} sm={12} xs={12}>
                                                <Form.Group className="mb-3" controlId="email">
                                                    <Form.Control required placeholder={t('Email')} className="nsu-form-control" type="text" name="email" value={userData?.email} onChange={handleFormChange} onBlur={userService.chekcEmailValidity} />
                                                    <Form.Control.Feedback type="invalid">
                                                        {t('validEmail')}
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </Col>
                                            <Col lg={12} md={12} sm={12} xs={12}>
                                                <Form.Group className="mb-3" as={Col} controlId="password">
                                                    <Form.Control required placeholder={t('Password')} className="nsu-form-control" type="password" name="password" value={userData?.password} onChange={handleFormChange} onKeyUp={userService.checkPasswordComplexity} />
                                                    <Form.Control.Feedback type="invalid">
                                                        {t('invalidPasswordComplexity')}
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </Col>
                                            <Col lg={12} md={12} sm={12} xs={12}>
                                                <Form.Group className="mb-3" as={Col} controlId="password_repeat">
                                                    <Form.Control required placeholder={t('password_repeatPlaceholder')} className="nsu-form-control" type="password" name="password_repeat" value={userData?.password_repeat} onChange={handleFormChange} onKeyUp={checkPasswordSame} />
                                                    <Form.Control.Feedback type="invalid">
                                                        {t('invalidPasswordSame')}
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </Col>
                                            <Col lg={12} md={12} sm={12} xs={12}>
                                                <Form.Group className="mb-3" as={Col} controlId="newsletter">
                                                    <Form.Check type="checkbox" id="newsletter">
                                                        <Form.Check.Input type="checkbox" onChange={onSubscribeNewsletter} name="newsletter" />
                                                        <Form.Check.Label>{t('labelSubscribeNewsletter')}</Form.Check.Label>
                                                    </Form.Check>
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col lg={12} md={12} sm={12} xs={12}>
                                                <Button type="submit" className="btn btn-nsu color-danger" disabled={inAction}>
                                                    { inAction && 
                                                        <Spinner
                                                        as="span"
                                                        animation="border"
                                                        size="sm"
                                                        role="status"
                                                        aria-hidden="true" /> 
                                                    } {t('regisztracioBtn')}
                                                </Button>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col lg={12} md={12} sm={12} xs={12}>
                                                <Button className="btn btn-none-nsu top-space" onClick={() => {navigate('/login')}}>{t('btnBackToLogin')}</Button>
                                            </Col>
                                        </Row>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </Form>
                </Col>
            </Row>
        </Container>
    );
};

export default Register;