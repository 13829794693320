import axios from "axios";
import config from "../config.json";

class ProductService {

    private endpointPrefix = config.API.URL + '/product';
    private headerConfig = {
        'Content-Type': 'application/json'
    };

    public getProductsList(entityID: string) {
        return new Promise<any>((resolve, reject) => {
            axios.get(this.endpointPrefix + '/list/' + entityID).then((response: any) => {
                resolve(response);
            }).catch((error) => {
                resolve(error);
            });
        });
    }

    public formatCurrency(amount: string) {
        const numericAmount = Number(amount);
        const formattedNumber = numericAmount.toLocaleString('hu-HU');
        return `${formattedNumber} Ft`;
    }

}

export default ProductService;