import { useState } from "react";
import { Col, Container, Row, Card, Form, Button, Figure, Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import UserModel from '../../../models/user.model';
import { useNavigate } from "react-router-dom";
import UserService from '../../../services/User.service';
import { toast, ToastContainer } from "react-toastify";

const Reminder = () => {
    const { t } = useTranslation();
    const userService = new UserService();
    const [userData, setUserData] = useState<UserModel>();
    const [validated, setValidated] = useState(false);
    const [inAction, setInAction] = useState(false);
    const navigate = useNavigate();

    document.body.className='authBody';

    const onSendPasswordReminder = (event: any) => {
        setInAction(true);
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            setValidated(true);
            setInAction(false);
        }
        else {
            userService.forgottenPassword(userData as UserModel).then((response: any) => {
                if ('status' in response) {
                    if (response.status === 200) {
                        toast.success(t('reminderSuccessfull'));
                        setInAction(false);
                        const timer = setTimeout(() => {
                            navigate('/passwordchange');
                            clearTimeout(timer);
                        }, 2000);
                    }
                    else {
                        toast.error(t('reminderError'));
                    }
                }
            });
        }
        setInAction(false);
        event.preventDefault();
        event.stopPropagation();
    };

    const handleFormChange = (event: any) => {
        const { name, value } = event.target;
        setUserData({...userData as UserModel, [name]: value });
    };

    return (
        <Container>
            <ToastContainer />
            <Row className="content">
                <Col lg={4} md={6} sm={12} xs={12}>
                    <Form noValidate validated={validated} onSubmit={onSendPasswordReminder}>
                        <Row>
                            <Col lg={12} md={12} sm={12} xs={12}>
                                <Card className="login">
                                    <Card.Body>
                                        <Row>
                                            <Col lg={12} md={12} sm={12} xs={12}>
                                                <Figure className="logo"></Figure>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col lg={12} md={12} sm={12} xs={12}>
                                                <h1>{t('focimReminder')}</h1>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col lg={12} md={12} sm={12} xs={12}>
                                                <Form.Group className="mb-3" controlId="confirmationCode">
                                                    <Form.Control required placeholder={t('EmailPlaceholder')} className="nsu-form-control centered-text-input" type="text" name="email" value={userData?.email} onChange={handleFormChange} />
                                                    <Form.Control.Feedback type="invalid">
                                                        {t('reminderData')}
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col lg={12} md={12} sm={12} xs={12}>
                                                <Button disabled={inAction} type="submit" className="btn btn-nsu color-danger">
                                                    { inAction && 
                                                        <Spinner
                                                        as="span"
                                                        animation="border"
                                                        size="sm"
                                                        role="status"
                                                        aria-hidden="true" /> 
                                                    } {t('sendPasswordReminderBtn')}
                                                </Button>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col lg={12} md={12} sm={12} xs={12}>
                                                <Button className="btn btn-none-nsu top-space" onClick={() => {navigate('/login')}}>{t('btnBackToLogin')}</Button>
                                            </Col>
                                        </Row>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </Form>
                </Col>
            </Row>
        </Container>
    );
};

export default Reminder;