import { useEffect, useState } from "react";
import { Col, Container, Row, Card, Form, Button, Figure, Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import UserModel from '../../../models/user.model';
import UserService from '../../../services/User.service';
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

const Confirmation = () => {
    const { t } = useTranslation();
    const userService = new UserService();
    const [userData, setUserData] = useState<UserModel>();
    const [validated, setValidated] = useState(false);
    const [inAction, setInAction] = useState(false);

    document.body.className='authBody';

    const onSendConfirmation = (event: any) => {
        setInAction(true);
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            setValidated(true);
            setInAction(false);
        }
        else {
            userService.checkConfirmationCode(userData as UserModel).then((response: any) => {
                if ('status' in response) {
                    switch (response.status) {
                        case 200:
                            toast.success(t('confirmationSuccessfull'));
                            localStorage.setItem('userData', JSON.stringify(userData));
                            window.dispatchEvent(new Event('userDataChange'));
                            const timer = setTimeout(() => {
                                window.location.href = '/profile';
                                clearTimeout(timer);
                            }, 1000);
                        break;
                        default:
                            toast.error(t('confirmationError'));
                            setInAction(false);
                        break;
                    }
                }
            });
        }
        event.preventDefault();
        event.stopPropagation();
    };

    const handleFormChange = (event: any) => {
        const { name, value } = event.target;
        setUserData({...userData as UserModel, [name]: value });
    };

    useEffect(() => {
        const user: any = userService.getUser();
        setUserData(user);
    }, []);

    return (
        <Container>
            <ToastContainer />
            <Row className="content">
                <Col lg={4} md={6} sm={12} xs={12}>
                    <Form noValidate validated={validated} onSubmit={onSendConfirmation}>
                        <Row>
                            <Col lg={12} md={12} sm={12} xs={12}>
                                <Card className="login">
                                    <Card.Body>
                                        <Row>
                                            <Col lg={12} md={12} sm={12} xs={12}>
                                                <Figure className="logo"></Figure>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col lg={12} md={12} sm={12} xs={12}>
                                                <h1>{t('focimConfirmation')}</h1>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col lg={12} md={12} sm={12} xs={12}>
                                                <Form.Group className="mb-3" controlId="confirmationCode">
                                                    <Form.Control required placeholder={t('labelConfirmationCode')} className="nsu-form-control centered-text-input" type="text" name="confirmationCode" value={userData?.confirmationCode} onChange={handleFormChange} />
                                                    <Form.Control.Feedback type="invalid">
                                                        {t('validConfirmationCode')}
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col lg={12} md={12} sm={12} xs={12}>
                                                <Button type="submit" className="btn btn-nsu color-danger" disabled={inAction}>
                                                    { inAction && 
                                                        <Spinner
                                                        as="span"
                                                        animation="border"
                                                        size="sm"
                                                        role="status"
                                                        aria-hidden="true" /> 
                                                    } {t('confirmationBtn')}
                                                </Button>
                                            </Col>
                                        </Row>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </Form>
                </Col>
            </Row>
        </Container>
    );
};

export default Confirmation;