import { useEffect, useState } from "react";
import { Col, Container, Row, Card, Button, Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import Menu from "../layouts/Menu";
import { toast, ToastContainer } from "react-toastify";
import PaymentService from "../../services/Payment.service";
import CartModel from "../../models/cart.model";

const Order = (props: any) => {

    const { t } = useTranslation();
    const [inAction, setInAction] = useState(false);
    const paymentService = new PaymentService();

    document.body.className='';

    useEffect(() => {
    }, []);

    const initPayment = async () => {
        setInAction(true);
        const cartJSON = localStorage.getItem('cart');
        const cartObj = cartJSON ? JSON.parse(cartJSON) as CartModel : {};
        const userJSON = localStorage.getItem('userData');
        const userObj = userJSON? JSON.parse(userJSON) : {};
        paymentService.startPayment(cartObj, userObj).then((response: any) => {
            if ('status' in response) {
                if (response.status === 200) {
                    cartObj.currency = response.data.data.currency;
                    cartObj.merchant = response.data.data.merchant;
                    cartObj.orderRef = response.data.data.orderRef;
                    cartObj.paymentUrl = response.data.data.paymentUrl;
                    cartObj.responseSignatureValid = response.data.data.responseSignatureValid;
                    cartObj.transactionId = response.data.data.transactionId;
                    localStorage.setItem('cart', JSON.stringify(cartObj));
                    if (cartObj.paymentUrl && cartObj.paymentUrl !== '') {
                        window.location.href = cartObj.paymentUrl;
                        setInAction(false);
                    }
                }
                else {
                    toast.error(t('paymentError'));
                }
                setInAction(false);
            }
        });
    };

    return (
        <>
            <Menu />
            <ToastContainer />
            <Container>
                <Row className="content align-items-start">
                    <Col lg={12} md={12} sm={12} xs={12} className="info-panel">
                        <Card className="places bottom-spaced">
                            <Card.Header className="large-padding-header">
                                {t('OrderTitle')}
                            </Card.Header>
                            <Card.Body className="large-padding top-padding">
                                <Row>
                                    <Col md={12} sm={12} xs={12}>
                                        <p>{t('OrderDescription')}</p>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg={12} md={12} sm={12} xs={12} className="top-min-margin">
                                        <Button type="button" onClick={initPayment} className="btn btn-middle-nsu color-danger" disabled={inAction}>
                                            { inAction && 
                                                <Spinner
                                                as="span"
                                                animation="border"
                                                size="sm"
                                                role="status"
                                                aria-hidden="true" /> 
                                            } {t('goToSimplePayBtn')}
                                        </Button>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>
    );
};

export default Order;