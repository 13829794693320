import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Alert, Col, Row } from "react-bootstrap";

const ReservationGraph = (props: any) => {
    const { t } = useTranslation();
    const [tracks, setTracks] = useState<any>();

    useEffect(() => {
        if (props.reservationData && props.reservationData.reservation) {
            const tracksData = props.reservationData.reservation;
            const mappedData = Object.keys(tracksData).map(key => ({
                date: key,
                list: tracksData[key]
            }));
            const today = props.day !== '' ? props.day : new Date().toISOString().split('T')[0];
            const todayTrackInfo = mappedData.find((track: any) => track.date === today);
            if (todayTrackInfo && todayTrackInfo.list) {
                let timeTable = Object.keys(todayTrackInfo.list).map(key => ({
                    time: key,
                    info: todayTrackInfo?.list[key]
                }));
                setTracks(timeTable);
            }
        }
    },[props.reservationData]);

    return (
        <>
            <Row>
                <Col className="chart-container" lg={12} md={12} sm={12} xs={12}>
                    {tracks && tracks.length > 0 ? 
                    <>
                        <div className="chart">
                            {tracks && tracks.map((track: any) => (
                                <div className="bar">
                                    <div className="segment gray"></div>
                                    <div className={track.info.percent < 50 ? 'segment green' : track.info.percent >= 50 && track.info.percent < 75 ? 'segment orange' : 'segment red'} style={{height: track.info.percent + 'px', marginTop: track.info.percent*-1 + 'px'}}></div>
                                    <div className="time-label">{track.time.substring(0,5)}</div>
                                </div>
                            ))}
                        </div>
                        <div className="ruler"></div>
                    </>
                    : 
                        <Alert key={'light'} variant={'light'}>
                            {t('noReservations')}
                        </Alert>
                    }
                </Col>
            </Row>
        </>
    );
};

export default ReservationGraph;