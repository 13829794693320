import axios from "axios";
import config from "../config.json";
import UserModel from "../models/user.model";

class TicketsService {

    private endpointPrefix = config.API.URL + '/user';
    private invoiceEndpoint = config.API.URL + '/invoice';
    private headerConfig = {
        'Content-Type': 'application/json'
    };

    public getUserTickets(userdata: UserModel, ticketType: number) {
        return new Promise<any>((resolve, reject) => {
            axios.get(this.endpointPrefix + '/ticket/' + userdata.id + '?active=' + ticketType + '&SMB=' + userdata.SMB)
            .then((response: any) => {
                resolve(response);
            })
            .catch((error) => {
                resolve(error);
            });
        });
    }

    public resendInvoice(userdata: UserModel, invoiceId?: string) {
        return new Promise<any>((resolve, reject) => {
            axios.get(this.invoiceEndpoint + '/resend/' + invoiceId + '?SMB=' + userdata.SMB)
            .then((response: any) => {
                resolve(response);
            })
            .catch((error) => {
                resolve(error);
            });
        });
    }

}

export default TicketsService;