import { useEffect, useState } from "react";
import { Col, Container, Row, Card } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import Menu from "../layouts/Menu";
import PlaceModel from "../../models/place.model";
import DataService from '../../services/Data.service';
import ReservationList from './ReservationList';
import config from "../../config.json";

const Reservations = (props: any) => {

    const picturesURL = 'https://pic.nsu.urbanms.hu/';
    const thumbsFilename = 'thumb.jpg';
    const { t } = useTranslation();
    const dataService = new DataService();
    const [places, setPlaces] = useState<PlaceModel[]>([]);
    const [inAction, setInAction] = useState(false);
    const [selectedPlace, setSelectedPlace] = useState('');
    const [placeDetails, setPlaceDetails] = useState<PlaceModel>()
    const [showGallery, setShowGallery] = useState(false);
    const [modalImage, setModalImage] = useState('');
    const [activeTab, setActiveTab] = useState('places');

    document.body.className='';

    useEffect(() => {
        setActiveTab(props.subpage);
        loadPlaces();
    }, []);

    const loadPlaces = async () => {
        setInAction(true);
        dataService.getInstitutions().then((response: any) => {
            if ('status' in response) {
                if (response.status === 200) {
                    setPlaces(response.data);
                }
            }
            setInAction(false);
        });
    };

    const onPlaceSelect = (place: PlaceModel) => {
        const id = 'id' in place && place.id !== undefined ? place.id : '';
        setSelectedPlace(id.toString());
        setPlaceDetails(place);
    };

    const showGalleyItem = (image: string) => {
        setShowGallery(true);
        setModalImage(image);
    };

    return (
        <>
            <Menu />
            <Container>
                <Row className="content align-items-start">
                    <Col lg={6} md={12} sm={12} xs={12} className="info-panel">
                        <Card className="places bottom-spaced">
                            <Card.Header className="large-padding-header">
                                {t('PlacesTitle')}
                            </Card.Header>
                            <Card.Body className="large-padding">
                                <Row>
                                    {places.map((place: PlaceModel, index: number) => (
                                        <Col key={index} lg={4} md={6} sm={12} xs={12} className={'place'}>
                                            <Card className="place-card" onClick={() => {onPlaceSelect(place)}}>
                                                <Card.Img variant="top" style={{backgroundImage: `url(${picturesURL + place.id + '/' + thumbsFilename})`}} />
                                                <Card.Body>
                                                    <Card.Title>{place.label}</Card.Title>
                                                    <Card.Text>
                                                        {t('sportuszoda')}
                                                    </Card.Text>
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                    ))}
                                </Row>
                                <Row>
                                    {selectedPlace !== '' && 
                                        <Col key={selectedPlace} lg={12} md={12} sm={12} xs={12}>
                                            <h3>{placeDetails?.name}</h3>

                                            <h4>{t('Elérhetőségek')}:</h4>
                                            <table>
                                                <tr>
                                                    <td className="table-label">{t('Cím')}: </td>
                                                    <td className="table-value">{placeDetails?.zip} {placeDetails?.town}, {placeDetails?.address}</td>
                                                </tr>
                                                <tr>
                                                    <td className="table-label">{t('Telefon')}: </td>
                                                    <td className="table-value"><a href={'callto:' + placeDetails?.phone}>{placeDetails?.phone}</a></td>
                                                </tr>
                                                <tr>
                                                    <td className="table-label">{t('Web')}: </td>
                                                    <td className="table-value"><a href={'http://' + placeDetails?.web} target="_blank" rel="noreferrer">{placeDetails?.web}</a></td>
                                                </tr>
                                                <tr>
                                                    <td className="table-label">{t('Email')}: </td>
                                                    <td className="table-value"><a href={'mailto:' + placeDetails?.email}>{placeDetails?.email}</a></td>
                                                </tr>
                                            </table>

                                            <h4>{t('Nyitva tartás')}:</h4>
                                            <table>
                                                <tr>
                                                    <td className="table-label">{t('Hétfő')}: </td>
                                                    <td className="table-value">{placeDetails?.opening_hours.monday}</td>
                                                </tr>
                                                <tr>
                                                    <td className="table-label">{t('Kedd')}: </td>
                                                    <td className="table-value">{placeDetails?.opening_hours.tuesday}</td>
                                                </tr>
                                                <tr>
                                                    <td className="table-label">{t('Szerda')}: </td>
                                                    <td className="table-value">{placeDetails?.opening_hours.wednesday}</td>
                                                </tr>
                                                <tr>
                                                    <td className="table-label">{t('Csütörtök')}: </td>
                                                    <td className="table-value">{placeDetails?.opening_hours.thursday}</td>
                                                </tr>
                                                <tr>
                                                    <td className="table-label">{t('Péntek')}: </td>
                                                    <td className="table-value">{placeDetails?.opening_hours.friday}</td>
                                                </tr>
                                                <tr>
                                                    <td className="table-label">{t('Szombat')}: </td>
                                                    <td className="table-value">{placeDetails?.opening_hours.saturday}</td>
                                                </tr>
                                                <tr>
                                                    <td className="table-label">{t('Vasárnap')}: </td>
                                                    <td className="table-value">{placeDetails?.opening_hours.sunday}</td>
                                                </tr>
                                            </table>

                                            <h4>{t('Részletek')}:</h4>
                                            <p>{placeDetails?.description}</p>
                                        </Col>
                                    }
                                </Row>
                            </Card.Body>
                            {selectedPlace !== '' && 
                            <Card.Footer className="gallery-container">
                                <Row>
                                    <Col key="gallery" lg={4} md={6} sm={12} xs={12}>
                                        <img className="gallery-image" src={config.API.MEDIA_CDN + '/' + placeDetails?.id + '/gallery/01.jpg'} onClick={() => { showGalleyItem('01'); }} alt={placeDetails?.label} />
                                    </Col>
                                    <Col key="gallery" lg={4} md={6} sm={12} xs={12}>
                                        <img className="gallery-image" src={config.API.MEDIA_CDN + '/' + placeDetails?.id + '/gallery/02.jpg'} onClick={() => { showGalleyItem('02'); }} alt={placeDetails?.label} />
                                    </Col>
                                    <Col key="gallery" lg={4} md={6} sm={12} xs={12}>
                                        <img className="gallery-image" src={config.API.MEDIA_CDN + '/' + placeDetails?.id + '/gallery/03.jpg'} onClick={() => { showGalleyItem('03'); }} alt={placeDetails?.label} />
                                    </Col>
                                    <Col key="gallery" lg={4} md={6} sm={12} xs={12}>
                                        <img className="gallery-image" src={config.API.MEDIA_CDN + '/' + placeDetails?.id + '/gallery/04.jpg'} onClick={() => { showGalleyItem('04'); }} alt={placeDetails?.label} />
                                    </Col>
                                    <Col key="gallery" lg={4} md={6} sm={12} xs={12}>
                                        <img className="gallery-image" src={config.API.MEDIA_CDN + '/' + placeDetails?.id + '/gallery/05.jpg'} onClick={() => { showGalleyItem('05'); }} alt={placeDetails?.label} />
                                    </Col>
                                    <Col key="gallery" lg={4} md={6} sm={12} xs={12}>
                                        <img className="gallery-image" src={config.API.MEDIA_CDN + '/' + placeDetails?.id + '/gallery/06.jpg'} onClick={() => { showGalleyItem('06'); }} alt={placeDetails?.label} />
                                    </Col>
                                </Row>
                            </Card.Footer>
                            }
                        </Card>
                    </Col>
                    <Col lg={6} md={12} sm={12} xs={12} className="products-panel">
                        <Card className="reservations">
                            <Card.Header className="large-padding-header">
                                {selectedPlace === '' ? t('noSelectedPlaceForReservations') : t('ReservationsTitle')}
                                {selectedPlace && <span className="subtitle">{t('foglaltságok')}</span>}
                            </Card.Header>
                            <Card.Body className="large-padding top-padding">
                                <Row>
                                    {selectedPlace !== '' && 
                                        <Col key={selectedPlace} lg={12} md={12} sm={12} xs={12}>
                                            <ReservationList placeID={selectedPlace}></ReservationList>
                                        </Col>
                                    }
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>
    );
};

export default Reservations;