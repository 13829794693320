import { useEffect, useState } from "react";
import { Col, Row, Form, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import UserModel from '../../models/user.model';
import UserService from '../../services/User.service';
import { toast, ToastContainer } from "react-toastify";

const FieldEditor = ({ fieldName, defaultValue, secureText, buttonField, disabled, repeat }: { fieldName: string, defaultValue: string, secureText: boolean, buttonField: string, disabled: boolean, repeat?: string }) => {
    const { t } = useTranslation();
    const [userData, setUserData] = useState<UserModel>();
    const [displayData, setDisplayData] = useState('');
    const [displayDataRepeat, setDisplayDataRepeat] = useState('');
    const [editMode, setEditMode] = useState(false);

    const userService = new UserService();

    useEffect(() => {
        const user = localStorage.getItem('userData');
        const userData: any = user ? JSON.parse(user) : {};
        setUserData(userData);
        let requestedData = '';
        requestedData = fieldName in userData && userData[fieldName] !== undefined ? userData[fieldName] : defaultValue;
        setDisplayData(requestedData);
        setDisplayDataRepeat(requestedData);
    }, []);

    const onChangeToEdit = (event: any) => {
        setEditMode(true);
    };

    const onCancelEdit = (event: any) => {
        setEditMode(false);
    };

    const onFieldChange = (event: any) => {
        let value = event.target.value;
        if (event.target.name === repeat) {
            setDisplayDataRepeat(value);
        }
        else {
            setDisplayData(value);
        }
    };

    const onSaveChangedField = (event: any) => {
        let isValid = false;
        if (secureText && displayData === displayDataRepeat && displayData !== '' && displayDataRepeat !== '') {
            isValid = true;
        }
        else if (!secureText && displayData !== '') {
            isValid = true;
        }

        if (isValid) {
            let changedUserData: any = userData;
            changedUserData[fieldName] = displayData;
            setUserData(changedUserData);
            if (userData) {
                localStorage.setItem('userData', JSON.stringify(userData));
                userService.modifyUser(userData).then((response: any) => {
                    if ('status' in response) {
                        if (response.status === 200) {
                            toast.success(t('profileUpdated'));
                        }
                        else {
                            toast.error(t('profileUpdateError'));
                        }
                    }
                });    
            }
            setEditMode(false);
        }
    };

    return (
        <>
            <ToastContainer />
            {!editMode ?
            <div className="field-editor">
                <Row className="align-items-center">
                    <Col md={9} sm={12} xs={12}>
                        <label>{t(fieldName)}</label>
                        {}
                        <span>{secureText ? userService.hideText(displayData, '*') : displayData}</span>
                    </Col>
                    <Col md={3} sm={12} xs={12}>
                        { !disabled && 
                            <Button className="btn btn-inline-nsu" onClick={onChangeToEdit}>{t(buttonField)}</Button>
                        }
                    </Col>
                </Row>
            </div>
            :
            <div className="field-editor">
                {secureText ? (
                    <Row className="align-items-center">
                        <Col md={9} sm={12} xs={12}>
                            <label>{t('PasswordPlaceholder')}</label>
                            <Form.Control type="password" value={displayData} name={fieldName} onChange={onFieldChange} />
                        </Col>
                        <Col md={9} sm={12} xs={12} className="input-repeater">
                            <label>{t('password_repeatPlaceholder')}</label>
                            <Form.Control type="password" value={displayDataRepeat} name={repeat} onChange={onFieldChange} />
                        </Col>
                        <Col md={3} sm={12} xs={12}>
                            <Button className="btn btn-inline-nsu top-space" onClick={onSaveChangedField}>{t('btnModify')}</Button>
                            <Button className="btn btn-none-nsu top-space" onClick={onCancelEdit}>{t('btnCancelModify')}</Button>
                        </Col>
                    </Row>
                ) : (
                    <Row className="align-items-center">
                    <Col md={9} sm={12} xs={12}>
                        <Form.Control type="text" value={displayData} name={fieldName} onChange={onFieldChange} />
                    </Col>
                    <Col md={3} sm={12} xs={12}>
                        <Button className="btn btn-inline-nsu" onClick={onSaveChangedField}>{t('btnModify')}</Button>
                    </Col>
                    </Row>
                )
                }
            </div>
            }
        </>
    );
};

export default FieldEditor;