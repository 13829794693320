import { useEffect, useState } from "react";
import { Col, Row, Card, Form, Button, Spinner, FormSelect } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import UserModel from '../../models/user.model';
import UserService from "../../services/User.service";
import DataService from "../../services/Data.service";
import { toast, ToastContainer } from "react-toastify";
import BilliingAddress from "./BillingAddress";
import BilliingAddressModel from "../../models/billingaddress.model";
import BilliingAddressSelect from "./BillingAddressSelect";
import { useNavigate } from "react-router-dom";

const UserDetails = (props: any) => {
    const { t } = useTranslation();
    const userService = new UserService();
    const dataService = new DataService();
    const [userData, setUserData] = useState<UserModel>();
    const [validated, setValidated] = useState(false);
    const [inAction, setInAction] = useState(false);
    const [titles, setTitles] = useState([]);
    const [genders, setGenders] = useState([]);
    const navigate = useNavigate();

    document.body.className='';

    useEffect(() => {
        let user = userService.getUser() as UserModel;
        if (user.billingaddresses === undefined || user.billingaddresses?.length === 0) {
            const newBillingAddress: BilliingAddressModel = { 
                id: 0, 
                billingname: '', 
                billingaddress: '', 
                billingcity: '', 
                billingzip: '', 
                taxnumber: ''};
            user['billingaddresses'] = [newBillingAddress];
            localStorage.setItem('userData', JSON.stringify(user));
        }
        setUserData(user);
        loadCollections();
    }, []);

    const loadCollections = () => {
        dataService.getTitles().then((resultTitles: any) => {
            if ('status' in resultTitles) {
                if (resultTitles.status === 200) {
                    setTitles(resultTitles.data);
                }
                else {
                    toast.error('Hiba az titulusok betöltésekor...');
                }
            }
        });

        dataService.getGenders().then((resultGenders: any) => {
            if ('status' in resultGenders) {
                if (resultGenders.status === 200) {
                    setGenders(resultGenders.data);
                }
                else {
                    toast.error('Hiba a nemek betöltésekor...');
                }
            }
        });
    };

    const onSaveProfile = (event: any) => {
        setInAction(true);
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            setValidated(true);
        }
        else {
            userService.modifyUser(userData as UserModel).then((response: any) => {
                if ('status' in response) {
                    if (response.status === 200) {
                        localStorage.setItem('userData', JSON.stringify(userData));
                        toast.success(t('profileUpdated'));
                        const timer = setTimeout(() => {
                            clearTimeout(timer);
                            window.location.href = '/dashboard';
                        }, 1000);
                    }
                    else {
                        toast.error(t('profileUpdateError'));
                    }
                }
                setInAction(false);
            });
        }
        setInAction(false);
        event.preventDefault();
        event.stopPropagation();
    };

    const handleFormChange = (event: any) => {
        const { name, value } = event.target;
        setUserData({...userData as UserModel, [name]: value });
    };

    const handleAddressChange = (address: BilliingAddressModel, index: number) => {
        const newUserData = { ...userData } as UserModel;
        if (address.billingname === '' || address.billingname === undefined || address.billingname === null) {
            address.billingname = newUserData.firstname + ' ' + newUserData.lastname;
        }
        if (newUserData.billingaddresses && newUserData.billingaddresses?.length > 0) {
            newUserData.billingaddresses[index] = address;
        }
        else {
            newUserData.billingaddresses = [address];
        }
        setUserData(newUserData);
        localStorage.setItem('userData', JSON.stringify(newUserData));
    };

    const deleteBillingAddress = (index: number) => {
        const newUserData = { ...userData } as UserModel;
        newUserData.billingaddresses?.splice(index, 1);
        setUserData(newUserData);
        localStorage.setItem('userData', JSON.stringify(newUserData));
    }

    const addNewBillingAddress = () => {
        const newBillingAddress: BilliingAddressModel = { taxnumber: '' };
        const newUserData = { ...userData } as UserModel;
        newUserData.billingaddresses?.push(newBillingAddress);
        setUserData(newUserData);
        localStorage.setItem('userData', JSON.stringify(newUserData));
    };

    const editProfile = () => {
        navigate('/profile');
    };

    return(
        <>
            <ToastContainer />
            <Form noValidate validated={validated} onSubmit={onSaveProfile} className="page-profile">
                <Row>
                    <Col lg={12} md={12} sm={12} xs={12}>
                        <Card className="profile">
                            <Card.Body>
                                <Row>
                                    <Col lg={12} md={12} sm={12} xs={12}>
                                        {!props.readOnly ? 
                                        <h1>{t('focimProfilAdatok')}</h1>
                                        : 
                                        <h1>{t('focimRendelesiAdatok')}</h1>
                                        }
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg={12} md={12} sm={12} xs={12} className="align-top">
                                        <Form.Group className="mb-3" controlId="title">
                                            <Form.Label>{t('Title')}</Form.Label>
                                            <Form.Select disabled={props.readOnly} className="nsu-form-control" name="title" value={userData?.title} onChange={handleFormChange}>
                                                <option value=""></option>
                                                {titles.length > 0 ? titles.map((option: any, index) => (
                                                    <option key={index} value={option.id}>{option.hu}</option>
                                                )) : null}
                                            </Form.Select>
                                            <Form.Control.Feedback type="invalid">
                                                {t('validText')}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg={6} md={12} sm={12} xs={12} className="align-top">
                                        <Form.Group className="mb-3" controlId="firstname">
                                            <Form.Label>{t('firstname')}</Form.Label>
                                            <Form.Control disabled={props.readOnly} required placeholder={t('firstnamePlaceholder')} className="nsu-form-control" type="text" name="firstname" value={userData?.firstname} onChange={handleFormChange} />
                                            <Form.Control.Feedback type="invalid">
                                                {t('validText')}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                    <Col lg={6} md={12} sm={12} xs={12} className="align-top">
                                        <Form.Group className="mb-3" controlId="lastname">
                                            <Form.Label>{t('lastname')}</Form.Label>
                                            <Form.Control disabled={props.readOnly} required placeholder={t('lastnamePlaceholder')} className="nsu-form-control" type="text" name="lastname" value={userData?.lastname} onChange={handleFormChange} />
                                            <Form.Control.Feedback type="invalid">
                                                {t('validText')}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg={12} md={12} sm={12} xs={12} className="align-top">
                                        <Form.Group className="mb-3" controlId="phone">
                                            <Form.Label>{t('phone')}</Form.Label>
                                            <Form.Control disabled={props.readOnly} required placeholder={t('phonePlaceholder')} className="nsu-form-control" type="text" name="phone" value={userData?.phone} onChange={handleFormChange} />
                                            <Form.Control.Feedback type="invalid">
                                                {t('validPhone')}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg={6} md={12} sm={12} xs={12} className="align-top">
                                        <Form.Group className="mb-3" controlId="birthdate">
                                            <Form.Label>{t('birthdate')}</Form.Label>
                                            <Form.Control disabled={props.readOnly} required className="nsu-form-control" type="date" name="birthdate" value={userData?.birthdate} onChange={handleFormChange} />
                                            <Form.Control.Feedback type="invalid">
                                                {t('validText')}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                    <Col lg={6} md={12} sm={12} xs={12} className="align-top">
                                        <Form.Group className="mb-3" controlId="gender">
                                            <Form.Label>{t('gender')}</Form.Label>
                                            <Form.Select disabled={props.readOnly} required className="nsu-form-control" name="gender" value={userData?.gender} onChange={handleFormChange}>
                                                <option value="">{t('genderPlaceholder')}</option>
                                                {genders.length > 0 ? genders.map((option: any, index) => (
                                                    <option key={index} value={option.id}>{option.hu}</option>
                                                )) : null}
                                            </Form.Select>
                                            <Form.Control.Feedback type="invalid">
                                                {t('validText')}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg={12} md={12} sm={12} xs={12}>
                                        <h1>{t('focimSzamlazasiAdatok')}</h1>
                                    </Col>
                                </Row>
                                <>
                                {userData?.billingaddresses && !props.readOnly ? userData.billingaddresses.map((address: any, index) => (
                                    <>
                                        <hr />
                                        <BilliingAddress address={address} index={index} onAddressDataChanged={handleAddressChange} onDeleteAddress={deleteBillingAddress}></BilliingAddress>
                                    </>
                                )) : props.readOnly ?
                                    <>
                                        <hr />
                                        <BilliingAddressSelect></BilliingAddressSelect>
                                    </>
                                : null
                                }
                                </>

                                {props.newBilling && !props.readOnly &&  
                                <Row>
                                    <Col lg={12} md={12} sm={12} xs={12}>
                                        <Button type="button" onClick={addNewBillingAddress} className="btn btn-none-nsu color-danger">
                                            + {t('focimUjSzamlazasiAdatok')}
                                        </Button>
                                    </Col>
                                </Row>
                                }

                                {props.readOnly &&  
                                <Row>
                                    <Col lg={12} md={12} sm={12} xs={12}>
                                        <Button type="button" onClick={editProfile} className="btn btn-middle-nsu color-danger">
                                            {t('Rendelési adatok szerkesztése')}
                                        </Button>
                                    </Col>
                                </Row>
                                }

                                {!props.readOnly && 
                                <Row>
                                    <Col lg={12} md={12} sm={12} xs={12} className="text-align-right">
                                        <Button type="submit" className="btn btn-middle-nsu color-danger" disabled={inAction}>
                                            { inAction && 
                                                <Spinner
                                                as="span"
                                                animation="border"
                                                size="sm"
                                                role="status"
                                                aria-hidden="true" /> 
                                            } {t('saveChangesBtn')}
                                        </Button>
                                    </Col>
                                </Row>
                                }
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Form>
        </>
    );
};

export default UserDetails;